import React, { useEffect } from 'react';
import './style.css';
import app from 'new-ui/app';
import { PAGES, URI } from 'new-ui/constants';
import { useTranslation } from 'utils/modifiedTranslation';
import WidgetBtn from '../Button';
import AssistanceButton from '../AssistanceButton';
import { FEED_GA } from '../constants';

const ProgramsWidget = ({ categories = [], programs = [] }) => {
  const { t } = useTranslation();
  const onBtnClick = () => {
    app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.programsInteracted, {
      widget: 'support programs',
    });
    app.history.push(`${URI[PAGES.FINANCE]}`);
  };

  const displayedCategories = categories.slice(0, 5);
  const remainingCount = categories.length - displayedCategories.length;

  useEffect(()=>{
    app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.programsViewed, {
      widget: 'support programs',
    });
  }, []);

  return (
    <div className="programs-widget">
      <div className="widget-header">
        <div className="details">
          <div className="count">{programs.length}</div>
          <span className="divider">/</span>
          <div className="label">{t('programs_widget.label')}</div>
        </div>
      </div>
      <div className="widget-body">
        <h2 className="title">{t('programs_widget.title')}</h2>
        <h3 className="sub-title">
          {programs.length}
          {' '}
          {t('programs_widget.available_programs')}
        </h3>
        <ul className="options-list">
          {displayedCategories.map((option, key) => (
            <li className="options-item" key={key}>
              <div className="icon"><img src={option.icon} alt="icon" /></div>
              <div>{option.label}</div>
            </li>
          ))}
          {remainingCount > 0 && (
            <li className="remaining-count">
              {`+${remainingCount}`}
            </li>
          )}
        </ul>
      </div>
      <div className="widget-footer">
        <WidgetBtn title={t('programs_widget.actions.view_programs.button')} onClick={onBtnClick} />
        <AssistanceButton title={t('programs_widget.actions.assistance.button')} onClick={()=>{ app.cache.assistTrigBy = 'supportProgram'; }} />
      </div>
    </div>
  );
};

export default ProgramsWidget;
