import React, { useState, useEffect } from 'react';
import './Trial.css';

import { useSelector } from 'react-redux';

import BackButton from 'new-ui/Components/BackButton';

import TrialItem from 'new-ui/Trial/Item';
import ClosestTrial from 'new-ui/Trial/Closest';
import TrialDetails from 'new-ui/Trial/Details';

import { fetchSponsoredTrialTemplateData } from 'modules/sponsoredTrialTemplate/api';

import { useParams } from 'react-router-dom';
import app from 'new-ui/app';
import { getTrialByNCT } from 'new-ui/api';
import { PAGES, ROUTES } from 'new-ui/constants';
import PublicationSection from 'new-ui/Treatment/PublicationSection/PublicationSection';
import WhatsNext from './WhatsNext';

const Trial = () => {
  const { nctNumber } = useParams();
  const [sponsoredTemplate, setSponsoredTemplate] = useState({});
  const [trial, setTrial] = useState([
    ...app.trials,
    ...(app.treatments || []),
    ...(app.related || []),
  ].find((i) => i.nct_number === nctNumber));

  const { lang } = useSelector((state) => ({
    lang: state?.main?.lang,
  }));

  useEffect(() => {
    const fetch = async ()=>{
      if (!app?.trials?.length) {
        await app.getTrials();
        const f = app.trials.find((a)=>a.nct_number === nctNumber);
        if (!f) {
          app.history.push(ROUTES[PAGES.RESULTS]);
          return;
        }
      }
      const usersCountry = app.user?.info?.country?.location;
      const trial_ = await getTrialByNCT({
        nctNumber,
        usersCountry,
        conditions: {
          cancerType: app?.user?.personal?.condition,
        },
      });
      if (trial_?.statusCode === 404) {
        app.history.push(ROUTES[PAGES.RESULTS]);
        return;
      }
      setTrial(trial_);
      if (trial?.isSponsored) getSponsoredTemplate();
    };
    const getSponsoredTemplate = async () => {
      console.log('getSponsoredTemplate');
      const facilityName = trial?.closest_facility?.facility_name;
      const conditions = {
        isMobile: false,
        isForPDF: false,
        lang,
        country: app?.user?.info?.country?.name || '',
        countryStates: app?.user?.info?.country?.state ? [app?.user?.info?.country?.state] : undefined,
        cancerType: app?.user?.personal?.condition || '',
        facilityNames: facilityName ? [facilityName] : undefined,
        nctNumbers: [trial.nct_number],
      };

      const r = await fetchSponsoredTrialTemplateData({
        nctId: trial.nct,
        conditions,
        nctNumber: nctNumber?.toUpperCase(),
        indication: app?.user?.personal?.condition || '',
      });

      console.log('fetchSponsoredTrialTemplateData', r);

      app._lastFetchedSponsored = r;

      if (r?.result?.length) {
        const template = {};
        r.result.forEach((item) => {
          if (String(item.blockName).startsWith('WhatNext')) {
            template.WhatsNext = item.content;
          }
          else {
            template[item.blockName] = item.content;
          }
        });
        setSponsoredTemplate(template);
      }
    };

    fetch();
    // eslint-disable-next-line
  }, []);

  // if (!trial) {
  //   debugger;
  //   history.push(ROUTES[PAGES.RESULTS]);
  //   return null;
  // }

  const renderActions = (type) => (
    <div>
      <div className={`${type === 'mobile' ? 'mobile-only' : 'desktop-only'} `}>
        { sponsoredTemplate.WhatsNext ? <WhatsNext content={sponsoredTemplate.WhatsNext} /> : null}
      </div>
    </div>
  );

  return trial ? (
    <div className="trial">
      <div className="back-button-holder"><BackButton /></div>
      <div className="grid">
        <div className="widget">
          <TrialItem
            isFull
            item={trial}
            title={(sponsoredTemplate?.HeaderTitle && sponsoredTemplate?.HeaderTitle[0]) || null}
            logoSrc={sponsoredTemplate?.Logo}
          />
          {renderActions('mobile')}
          <div className="height-20" />
          {trial?.closest_facility?.facility_id ? <ClosestTrial trial={trial} /> : null}
          <div className="height-20" />
          <TrialDetails trial={trial} content={sponsoredTemplate.Description ? sponsoredTemplate.Description : null} />
          <PublicationSection />
        </div>
        {renderActions('desktop')}
      </div>
    </div>
  ) : null;
};

export default Trial;
