import getGroupsFromTreatmentOrTrial from './getGroupsFromTreatmentOrTrial';
import formatTreatmentAndTrialGroups from './formatTreatmentAndTrialGroups';

export const formatMatchesResultFromApi = (rawMatchesResult) => {
  const matchesResult = {
    ...rawMatchesResult,
    result: rawMatchesResult.result ?? [],
    locations: rawMatchesResult.locations ?? [],
    trial_groups: rawMatchesResult.trial_groups ?? [],
  };

  matchesResult.result_count = matchesResult.result.length;
  matchesResult.locations = matchesResult.locations.filter((location) => (
    matchesResult.result.some((trial) => location.trial_ids.includes(trial._id))
  ));

  const groupsToDisplay = getGroupsFromTreatmentOrTrial(matchesResult.result);

  const { groupLabels, groupDescriptions, groupColors } = formatTreatmentAndTrialGroups(
    matchesResult.trial_groups,
    groupsToDisplay,
  );

  matchesResult.trial_groups = groupLabels;
  matchesResult.trial_group_descriptions = groupDescriptions;
  matchesResult.trial_groups_colors = groupColors;

  return matchesResult;
};
