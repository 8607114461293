import { useEffect, useRef } from 'react';

export const useDelayedAction = ({ shouldTrigger, action, delay }) => {
  const actionRef = useRef(action);

  useEffect(() => {
    actionRef.current = action;
  }, [action]);

  useEffect(() => {
    if (!shouldTrigger) {
      return;
    }

    const id = setTimeout(() => {
      actionRef.current();
    }, delay);

    return () => clearTimeout(id);
  }, [shouldTrigger, delay]);
};
