import React from 'react';
import PropTypes from 'prop-types';

export const AddCaregiverHeader = ({ title, subtitle }) => {
  return (
    <React.Fragment>
      <div className="add-caregiver-title">{title}</div>
      {!!subtitle && <div className="add-caregiver-subtitle">{subtitle}</div>}
    </React.Fragment>
  );
};

AddCaregiverHeader.propTypes = {
  title: PropTypes.any.isRequired,
  subtitle: PropTypes.any,
};
