import React, { useState, useEffect } from 'react';
import './Popups.css';

import app from 'new-ui/app';
import { string2obj } from 'new-ui/functions';

import Popup1 from 'new-ui/Components/Popup';
import { POPUP_TYPES } from 'new-ui/constants';

import { GA_GENERAL_CATEGORY, GA_GENERAL_EVENTS, GA_GENERAL_POPUPS_SOURCES } from 'new-ui/Components/GoogleAnalytics/generalEvents';

import Popup from 'new-ui/Components/Popup.2';
import NextScan from './NextScan';
import ChangeLocation from './ChangeLocation';
import MedicalProfilePopup from './MedicalProfile/index.2';
import Questionnaire from './Questionnaire';
import Questions from './Questions';
import UserIntent from './UserIntent';
import HasAnythingChanged from './HasAnythingChanged';
import NextAppointment from './NextAppointment';
import AddDoctor from './AddDoctor';
import SearchDoctor from './SearchDoctor';
import EditEmail from './EditEmail';
import EditQuestion from './EditQuestion';
import ConsultWithUs from './ConsultWithUs';
import CallUs from './CallUs';
import ShareWithDoctor from './ShareWithDoctor';
import ConnectWithOncologist from './ConnectWithOncologist';
import SeeAllOptions from './SeeAllOptions';
import TravelingPreferences from './TravelingPreferences';
import DebugPopup from './Debug';
import SendToDoctor from './SendToDoctor/SendToDoctor';
import MissingCriticalInformation from './MissingCriticalInformation';
import NGSPopup from './NGS';
import ContactUs from './ContactUs';
import Personalize from './Personalize';
import GeneralPopup from './General';
import TreatmentSurvey from './TreatmentSurvey';
import PreferenceCategory from './PreferenceCategory';
import PhoneNumber from './PhoneNumber';
import Signup from './Signup';
import UnsubscribeForm from './UnsubscribeForm';
import BiomarkersPopup from './Biomarkers';
import IntakePopup from './Intake';
import NewMatchesPopup from './NewMatches';
import AboutYouPopup from './AboutYou';
// import ChatPopup from './Chat';
import ChatUploadPopup from './ChatUpload';
import UploadPopup from './Upload';
import AddPatient from './AddPatient';
import AddCaregiver from './AddCaregiver';
import LoginPopup from './Login';
import ChatUploadExplainPopup from './ChatUploadExplain';
import AiAssistantPopup from './AiAssistant';
import ChatReturnUserPopup from './ChatReturnUser';
import ChatNewUserPopup from './ChatNewUser';
import { SignupHCP } from './SignupHCP/SignupHCPIndex';

export const POPUPS = {
  [POPUP_TYPES.SIGNUP]: Signup,
  [POPUP_TYPES.NGS]: NGSPopup,
  [POPUP_TYPES.NEXT_SCAN]: NextScan,
  [POPUP_TYPES.CHANGE_LOCATION]: ChangeLocation,
  [POPUP_TYPES.MEDICAL_PROFILE]: MedicalProfilePopup,
  [POPUP_TYPES.QUESTIONNAIRE]: Questionnaire,
  [POPUP_TYPES.QUESTIONS]: Questions,
  [POPUP_TYPES.USER_INTENT]: UserIntent,
  [POPUP_TYPES.HAS_ANYTHING_CHANGED]: HasAnythingChanged,
  [POPUP_TYPES.NEXT_APPOINTMENT]: NextAppointment,
  [POPUP_TYPES.ADD_DOCTOR]: AddDoctor,
  [POPUP_TYPES.SEARCH_DOCTOR]: SearchDoctor,
  [POPUP_TYPES.EDIT_EMAIL]: EditEmail,
  [POPUP_TYPES.CONSULT_WITH_US]: ConsultWithUs,
  [POPUP_TYPES.SHARE_WITH_DOCTOR]: ShareWithDoctor,
  [POPUP_TYPES.SEND_TO_DOCTOR]: SendToDoctor,
  [POPUP_TYPES.TREATMENT_SURVEY]: TreatmentSurvey,
  [POPUP_TYPES.TRAVELING_PREFERENCES]: TravelingPreferences,
  [POPUP_TYPES.SEE_ALL_OPTIONS]: SeeAllOptions,
  [POPUP_TYPES.CONNECT_WITH_ONCOLOGIST]: ConnectWithOncologist,
  [POPUP_TYPES.MISSING_CRITICAL_INFORMATION]: MissingCriticalInformation,
  [POPUP_TYPES.DEBUG]: DebugPopup,
  [POPUP_TYPES.CONTACT_US]: ContactUs,
  [POPUP_TYPES.PERSONALIZE]: Personalize,
  [POPUP_TYPES.GENERAL]: GeneralPopup,
  [POPUP_TYPES.PREFERENCE_CATEGORY]: PreferenceCategory,
  [POPUP_TYPES.PHONE_NUMBER]: PhoneNumber,
  [POPUP_TYPES.CALLUS]: CallUs,
  [POPUP_TYPES.UNSUBSCRIBE_FORM]: UnsubscribeForm,
  [POPUP_TYPES.BIOMARKERS]: BiomarkersPopup,
  [POPUP_TYPES.INTAKE]: IntakePopup,
  [POPUP_TYPES.NEW_MATCHES]: NewMatchesPopup,
  [POPUP_TYPES.ABOUT_YOU]: AboutYouPopup,
  [POPUP_TYPES.CHAT]: ChatNewUserPopup,
  [POPUP_TYPES.CHAT_RETURN_USER]: ChatReturnUserPopup,
  [POPUP_TYPES.CHAT_UPLOAD]: ChatUploadPopup,
  [POPUP_TYPES.CHAT_UPLOAD_EXPLAIN]: ChatUploadExplainPopup,
  [POPUP_TYPES.UPLOAD]: UploadPopup,
  [POPUP_TYPES.ADD_PATIENT]: AddPatient,
  [POPUP_TYPES.ADD_CAREGIVER]: AddCaregiver,
  [POPUP_TYPES.SIGNUP_HCP]: SignupHCP,
  [POPUP_TYPES.LOGIN]: LoginPopup,
  [POPUP_TYPES.AI_ASSISTANT]: AiAssistantPopup,
  [POPUP_TYPES.EDIT_QUESTION]: EditQuestion,
};

const POPUPS_PARAMS = {
  [POPUP_TYPES.NEXT_APPOINTMENT]: { isFullHeight: true },
};

let nextPopup = false;
let initPopup = false;

const POPUP_TIMEOUT_ON_INIT = 1500;

const DashboardPopups = () => {
  const params = new URLSearchParams(window.location.search);
  const popupType = params.get('popup');
  const popupData = string2obj(params.get('popup_data'));
  const providersPopup = app.user?.providersPopup;

  const [popup, setPopup] = useState(false);
  app.popup = popup;
  app.setPopup = (type, data = {}) => {
    // Temporary workaround to allow to edit preferences from dashboard
    if (type && popup && popup.type === POPUP_TYPES.UNSUBSCRIBE_FORM) {
      console.error(`[DashboardPopups] Can not set popup ${type} as ${popup.type} is active at the moment`);
      return;
    }
    window.popups.hideAll();
    if (type === false) {
      if (app.onpopupclose) app.onpopupclose();
      return setPopup(false);
    }
    // window.Intercom('hide');
    app.sendGoogleAnalyticsEvent(GA_GENERAL_CATEGORY, GA_GENERAL_EVENTS.POPUP_OPEN, { popup: type, source: GA_GENERAL_POPUPS_SOURCES.DASHBOARD });
    delete app.onpopupclose;
    if (data?.v2) {
      const popup = new Popup({
        type,
        data,
        closePopup,
      });
      return popup.show();
    }
    setPopup(type ? {
      type,
      data,
    } : false);
  };

  if (popupType && !initPopup && app.user) {
    setTimeout(() => {
      window.Intercom('hide');
      app.sendGoogleAnalyticsEvent(GA_GENERAL_CATEGORY, GA_GENERAL_EVENTS.POPUP_OPEN, { popup: popupType, source: GA_GENERAL_POPUPS_SOURCES.URL, data: params.get('source') });
      setPopup({
        type: popupType,
        data: { ...popupData, source: params.get('source') },
      });
      initPopup = true;
    }, POPUP_TIMEOUT_ON_INIT);
  }

  useEffect(() => {
    if (params.get('question')) {
      setTimeout(() => {
        app.editQuestion(params.get('question'), async () => {
          app.setPopup(POPUP_TYPES.MEDICAL_PROFILE, {
            highlight: params.get('question'),
            scrollTop: 0,
          });
          await app.update({ rematch: true });
          app.renderPage();
        });
      }, 1000);
    }
    if (params.get('questions')) {
      setTimeout(() => {
        app.setPopup(POPUP_TYPES.QUESTIONS, {
          questions: params.get('questions').split(','),
        });
      }, 1000);
    }
    if (params.get('unsubscribe_form')) {
      setTimeout(() => {
        app.setPopup(POPUP_TYPES.UNSUBSCRIBE_FORM);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (providersPopup) {
      const {
        noProvidersMatched,
      } = providersPopup;
      if (noProvidersMatched) {
        app.setPopup(POPUP_TYPES.SEE_ALL_OPTIONS);
      }
    }
  }, [providersPopup]);

  const closePopup = () => {
    if (nextPopup) {
      app.setPopup({
        type: nextPopup,
      });
      nextPopup = false;
    } else app.setPopup(false);
  };

  const getPopup = (p) => {
    if (!p) return null;
    const PopupContent = POPUPS[p.type];
    const data = p.data || {};
    return <PopupContent closePopup={closePopup} {...data} />;
  };

  let popupParams = {};

  if (popup?.type) popupParams = POPUPS_PARAMS[popup.type];
  return (
    <div className="popups">
      <Popup1
        isVisible={popup}
        theme={popup?.data?.theme}
        position={popup?.data?.position}
        closePopup={closePopup}
        popupData={popup?.data || {}}
        className={popup?.type || ''}
        {
          ...popupParams
        }
      >
        {getPopup(popup)}
      </Popup1>
    </div>
  );
};

export default DashboardPopups;
