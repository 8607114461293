export const FEED_GA_CATEGORY = 'FEED';

export const FEED_GA_EVENTS = {
  WIDGET_VIEWED: 'WIDGET_VIEWED',
  WIDGET_INTERACTED: 'WIDGET_INTERACTED',
};

export const FEED_GA = {
  category: 'Feed',
  events: {
    programsViewed: 'Widget Support programs viewed',
    treatmentsViewed: 'Widget treatment options viewed',
    experienceViewed: 'Widget treatment experience viewed',
    surveyViewed: 'Widget treatment survey viewed',
    quotesViewed: 'Widget quotes viewed',
    treatmentViewed: 'Widget treatment viewed',

    programsInteracted: 'Widget Support programs interacted',
    treatmentsInteracted: 'Widget treatment options interacted',
    experienceInteracted: 'Widget treatment experience interacted',
    surveyInteracted: 'Widget treatment survey interacted',
    treatmentInteracted: 'Widget treatment interacted',

    fileUploadClicked: 'feed upload file clicked',
    answerManuallyClicked: 'feed answer manually clicked',
    feedViewed: 'Feed viewed',

    treatment_q_viewed: 'Treatment q viewed 1st treatment feed',
    treatment_q_add_clicked: 'Treatment q add current clicked feed',
    treatment_q_add_first_clicked: 'Treatment q add first clicked feed',
    treatment_q_add_last_clicked: 'Treatment q add last clicked feed',
    treatment_q_not_received_clicked: 'Treatment q didn’t received clicked feed',
    treatment_q_need_assistance_clicked: 'Treatment q need assistance clicked feed',
    treatment_q_drug_added: 'Treatment q drug added feed',
    treatment_q_combination_added: 'Treatment q combination added feed',
    treatment_q_answered: 'Treatment q treatment answered feed',
    treatment_q_cant_find_treatment_clicked: 'Treatment q can’t find treatment clicked feed',
    treatment_q_cycles_answered: 'Treatment q cycles answered feed',
    treatment_q_timing_answered: 'Treatment q timing answered feed',
    treatment_q_status_answered: 'Treatment q status answered feed',
    treatment_q_reason_answered: 'Treatment q reason answered feed',
    treatment_q_add_more_clicked: 'Treatment q add more clicked feed',
    treatment_q_done_clicked: 'Treatment q done clicked feed',
    treatment_q_viewed_more: 'Treatment q viewed more treatments feed',
    treatment_q_still_taking_clicked: 'Treatment q still taking clicked feed',
    treatment_q_finished_looking_clicked: 'Treatment q finished looking clicked feed',
    treatment_q_finished_received_clicked: 'Treatment q finished received other clicked feed',
    treatment_q_disease_free_clicked: 'Treatment q disease free clicked feed',
  },
};
