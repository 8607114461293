import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Route, Switch, Redirect, withRouter,
} from 'react-router-dom';
import { connect, batch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Dashboard from 'new-ui/Dashboard';
import DashboardPopups from 'new-ui/Popups';
import PageLoader from 'components/PageLoader';
import Splash from 'components/Splash';
import { SimpleBackdrop } from 'components/SimpleBackdrop/SimpleBackdrop';
import ServerDown from 'components/AppRoutes/components/ServerDown';
import PrivateRoute from 'components/AppRoutes/components/PrivateRoute';
import ProtectedRoute from 'components/AppRoutes/components/ProtectedRoute';
import AppRouteContainer from 'components/AppRoutes/components/AppRouteContainer';
import { updateAppSettings } from 'utils/actions/appSettings';
import { authenticationActions } from 'components/Authentication/actions';
import { appConfigurationActions } from 'components/AppConfiguration/actions';
import { providerActions } from 'components/Provider/actions';
import { shouldHideNav } from 'components/NavBar/functions';
import { serverStatusActions } from 'utils/actions/serverStatus';
import { redirectToActions } from 'utils/actions/redirectTo';
import { commonActions } from 'utils/actions/common';
import { BACKDROP_TYPES } from 'modules/backdrop/constants';
import mainConstants from 'utils/constants/main';
import { confirmBeforeLeave } from 'components/Questionnaire/functions/confirmBeforeLeave';
import { ROUTE_NAME, ROUTE_PATH } from 'components/AppRoutes/constants';
import { getQueryDataFromRootRoute, isInlineEditing, shouldHideFooter } from 'components/AppRoutes/functions';
import { ROUTES } from 'new-ui/constants';
import apiService from 'new-ui/api';
import analyticsService from 'functions/analytics';
import { FB_EVENTS, FB_TRACK_TYPE } from 'functions/analytics/constants/fbKeys';
import WithoutNavRoute from './components/WithoutNavRoute';
import configs from '../../configs';

import 'new-ui/main.css';
import Preloader from 'new-ui/Components/Preloader';
import app from 'new-ui/app';

const QuestionnaireSelector = PageLoader({
  loader: () => import('components/QuestionnaireSelector'),
});
const Questionnaire = PageLoader({
  loader: () => import('components/Questionnaire'),
});
const Signup = PageLoader({
  loader: () => import('components/Authentication/components/Signup'),
});
const AfterSignupUserDetails = PageLoader({
  loader: () => import('components/Authentication/components/AfterSignupUserDetails/AfterSignupUserDetails'),
});
const Login = PageLoader({
  loader: () => import('components/Authentication/components/Login'),
});
const ForgotPassword = PageLoader({
  loader: () => import('components/Authentication/components/ForgotPassword'),
});
const NewPassword = PageLoader({
  loader: () => import('components/Authentication/components/NewPassword'),
});
const Matches = PageLoader({
  loader: () => import('components/Matches'),
});
const TrialPublic = PageLoader({
  loader: () => import('components/TrialPublic'),
});
const TrialsPublic = PageLoader({
  loader: () => import('components/TrialsPublic'),
});
const SendToClinicalTeam = PageLoader({
  loader: () => import('components/SendToClinicalTeam'),
});
const Sponsor = PageLoader({
  loader: () => import('components/Sponsor'),
});
const Footer = PageLoader({
  loader: () => import('components/Footer'),
});
const NavBar = PageLoader({
  loader: () => import('components/NavBar'),
});
const Dialogs = PageLoader({
  loader: () => import('components/Dialogs'),
});
const CheckIsMobile = PageLoader({
  loader: () => import('components/CheckIsMobile'),
});
const TrialFinder = PageLoader({
  loader: () => import('components/TrialFinder'),
});
const GeneticTesting = PageLoader({
  loader: () => import('components/GeneticTesting'),
});
const MedicalFiles = PageLoader({
  loader: () => import('components/MedicalFiles'),
});

const useStyles = makeStyles(() => ({
  appMain: {
    width: '100%',
  },
  providerAppMain: {
    width: '100%',
    margin: '0 auto',
    overflowY: 'auto',
    height: 'calc(100% - 30px) !important',
  },
  appMainContainer: {
    flexGrow: '1',
  },
  providerAppMainContainer: {
    width: '100%',
    flexGrow: '1',
    background: 'transparent',
    height: '100% !important',
  },
  providerRootAppRoutes: {
    height: '100%',
    background: 'white',
    boxShadow: 'rgb(233 222 226) 0px 0px 5px 0px',
    overflow: 'hidden',
  },
  providerWrapper: {
    height: '100%',
  },
}));

const renderBackdrop = ({ isShown, type }) => {
  const Component = {
    [BACKDROP_TYPES.SPLASH]: Splash,
    [BACKDROP_TYPES.SIMPLE_BACKDROP]: SimpleBackdrop,
  }[type];
  return isShown && Component ? <Component /> : null;
};

const AppRouteContent = ({
  serverStatus,
  isMobile,
  location,
  questionnaire,
  indicationName,
  pageName,
  backdrop,
  provider,
}) => {
  const classes = useStyles({ provider });
  const [showNav, setShowNav] = useState(true);
  const [showPreloader, setShowPreloader] = useState(false);

  app.setShowPreloader = setShowPreloader;

  if (serverStatus.isFetching) {
    return null;
  }

  if (serverStatus.error) {
    return <ServerDown />;
  }

  const getStepNumber = ({ currentIndex, qstack }) => {
    if (currentIndex === 0) return 1;
    if (qstack.length) return currentIndex + 1;
    return 0;
  };
  return (
    <React.Fragment>
      {renderBackdrop(backdrop)}
      <div className={provider ? classes.providerRootAppRoutes : ''}>
        {!shouldHideNav() && showNav && <NavBar />}
        <Grid className={provider ? classes.providerAppMainContainer : classes.appMainContainer} container>
          <main
            className={provider ? classes.providerAppMain : classes.appMain}
            step_number={getStepNumber(questionnaire)}
            indication_name={indicationName}
            page_name={pageName}
          >
            <div className={provider ? classes.providerWrapper : ''}>
              <CheckIsMobile />
              <Dialogs />
              <Switch>
                <Route exact path={ROUTE_PATH[ROUTE_NAME.HOME]} component={QuestionnaireSelector} />
                <ProtectedRoute path={ROUTE_PATH[ROUTE_NAME.QUESTIONNAIRE_QUESTIONS]} component={Questionnaire} />
                <ProtectedRoute path={ROUTE_PATH[ROUTE_NAME.QUESTIONNAIRE]} component={Questionnaire} />
                <ProtectedRoute
                  isProfileRequired
                  notRegistered
                  path={ROUTE_PATH[ROUTE_NAME.SIGN_UP]}
                  component={Signup}
                />
                <PrivateRoute
                  path={ROUTE_PATH[ROUTE_NAME.AFTER_SIGNUP_USER_DETAILS]}
                  component={AfterSignupUserDetails}
                />
                <PrivateRoute path={ROUTE_PATH[ROUTE_NAME.SPONSOR]} component={Sponsor} />
                <Route path={ROUTE_PATH[ROUTE_NAME.FORGOT_PASSWORD]} component={ForgotPassword} exact />
                <Route path={ROUTE_PATH[ROUTE_NAME.CREATE_PASSWORD]} component={ForgotPassword} exact />
                <Route path={ROUTE_PATH[ROUTE_NAME.NEW_PASSWORD]} component={NewPassword} exact />
                <Route path={ROUTE_PATH[ROUTE_NAME.LOGIN]} component={Login} exact />
                <Route path={ROUTE_PATH[ROUTE_NAME.TRIALS]} component={TrialsPublic} />
                <Route path={ROUTE_PATH[ROUTE_NAME.TRIAL]} component={TrialPublic} />
                <Route path={ROUTE_PATH[ROUTE_NAME.TRIAL_FINDER]} component={TrialFinder} />
                <Route path={ROUTE_PATH[ROUTE_NAME.MEDICAL_FILES]} component={MedicalFiles} />
                <WithoutNavRoute
                  setShowNav={setShowNav}
                  path={ROUTE_PATH[ROUTE_NAME.GENETIC_TESTING]}
                  component={GeneticTesting}
                />
                <PrivateRoute path={ROUTE_PATH[ROUTE_NAME.MATCHES]} component={Matches} />
                {/* <Route
                path={ROUTE_PATH[ROUTE_NAME.LOGOUT]}
                component={LogoutPage}
              /> */}
                <Route path={ROUTE_PATH[ROUTE_NAME.SEND_TO_CLINICAL_TEAM]} component={SendToClinicalTeam} />
                <Redirect to={ROUTE_PATH[ROUTE_NAME.HOME]} />
              </Switch>
            </div>
          </main>
          <Preloader isVisible={showPreloader} />
          <DashboardPopups />
        </Grid>
      </div>
      {!shouldHideFooter(location.pathname, isMobile, provider, questionnaire?.showIntro) && <Footer />}
    </React.Fragment>
  );
};

class AppRoutes extends React.Component {
  componentDidMount() {
    const { dispatch, location } = this.props;

    const actionsToDispatch = [];
    const queueActionToDispatch = (action) => {
      actionsToDispatch.push(action);
    };

    queueActionToDispatch(serverStatusActions.checkServerStatus());

    const {
      partner, int, redirectToActionProps, provider, survey,
    } = getQueryDataFromRootRoute();

    if (partner) {
      queueActionToDispatch(authenticationActions.setPartner(partner));
    }

    if (int) {
      queueActionToDispatch(authenticationActions.setInternal());
    }

    const isInlineEditingMode = isInlineEditing();
    if (provider && !isInlineEditingMode) {
      window._provider = provider;
      queueActionToDispatch(providerActions.setProvider(provider));
    }

    if (survey) {
      queueActionToDispatch(updateAppSettings({ showSurvey: survey }));
    }
    if (!isInlineEditingMode) {
      queueActionToDispatch(appConfigurationActions.getConfiguration({ provider }));
    }

    if (redirectToActionProps) {
      queueActionToDispatch(redirectToActions.setAfter(redirectToActionProps));
    }

    if (document.referrer) {
      queueActionToDispatch(commonActions.setCameFrom(document.referrer));
    }

    if (location.pathname || location.search) {
      queueActionToDispatch(commonActions.setUrlParameters(`${location.pathname}${location.search}`));
    }

    batch(() => {
      actionsToDispatch.forEach((action) => {
        dispatch(action);
      });
    });
  }

  componentDidUpdate() {
    const {
      loggedIn,
      cameFrom,
      urlParameters,
      dispatch,
      lastOpenedUrl,
    } = this.props;
    const url = window?.location.href || '';
    if (lastOpenedUrl !== url) {
      dispatch({
        type: mainConstants.SET_LAST_OPENED_URL,
        payload: url,
      });

      const eventID = `${FB_EVENTS.PAGE_VIEW}.${Date.now()}`;
      if (
        typeof window.fbq === 'function'
        && !!configs.metaPixelId
      ) {
        window.fbq(
          FB_TRACK_TYPE.TRACK_SINGLE,
          configs.metaPixelId,
          FB_EVENTS.PAGE_VIEW,
          {},
          { eventID },
        );
      }
      analyticsService.sendFbEventToConversionApi({
        eventName: FB_EVENTS.PAGE_VIEW,
        eventID,
      }).catch((e) => console.error('Can\'t send facebook event', e));
    }

    const conditionsForUrl = ['provider', 'source', 'refid', 'term', 'redirectTo'];
    if (
      loggedIn
      && conditionsForUrl.some((condition) => urlParameters?.includes(condition))
    ) {
      apiService.saveProviderInfo({
        cameFrom,
        urlParameters: urlParameters.split('?').pop(),
      }).catch((e) => console.error('Can\'t save provider info', e));

      dispatch({ type: mainConstants.CLEAR_URL_PARAMETERS_AND_CAME_FROM });
    }
  }

  render() {
    const {
      location,
      serverStatus,
      isMobile,
      questionnaire,
      indicationName,
      pageName,
      backdrop,
      provider,
    } = this.props;

    // NOTE: this will be changed to fit current syntax
    let isDashboard = false;
    for (const x in ROUTES) if (location.pathname.startsWith(ROUTES[x])) isDashboard = true;

    if (
      !location.pathname.startsWith(ROUTE_PATH[ROUTE_NAME.SIGN_UP])
      && !location.pathname.startsWith(`/${ROUTE_PATH[ROUTE_NAME.QUESTIONNAIRE].split('/')[1]}`)
    ) {
      window.removeEventListener('beforeunload', confirmBeforeLeave);
    }

    document.body.setAttribute('mode', isDashboard ? 'dashboard' : 'questionnaire');

    if (isDashboard) {
      return <Dashboard />;
    }

    return (
      <AppRouteContainer>
        <AppRouteContent
          serverStatus={serverStatus}
          isMobile={isMobile}
          location={location}
          questionnaire={questionnaire}
          indicationName={indicationName}
          pageName={pageName}
          backdrop={backdrop}
          provider={provider}
        />
      </AppRouteContainer>
    );
  }
}

AppRouteContent.propTypes = {
  location: PropTypes.object.isRequired,
  serverStatus: PropTypes.shape({
    isFetching: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  questionnaire: PropTypes.object.isRequired,
  indicationName: PropTypes.string,
  pageName: PropTypes.string,
  backdrop: PropTypes.shape({
    isShown: PropTypes.bool.isRequired,
    type: PropTypes.string,
  }).isRequired,
  provider: PropTypes.string,
  lastOpenedUrl: PropTypes.string,
};

AppRoutes.propTypes = {
  location: PropTypes.object.isRequired,
  serverStatus: PropTypes.shape({
    isFetching: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  questionnaire: PropTypes.object.isRequired,
  indicationName: PropTypes.string,
  pageName: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  backdrop: PropTypes.shape({
    isShown: PropTypes.bool.isRequired,
    type: PropTypes.string,
  }).isRequired,
  loggedIn: PropTypes.bool,
  cameFrom: PropTypes.string,
  urlParameters: PropTypes.string,
  provider: PropTypes.string,
  lastOpenedUrl: PropTypes.string,
};

const StyledAppRoutes = withRouter(AppRoutes);

const mapStateToProps = (state) => ({
  serverStatus: state.serverStatus,
  isMobile: state?.main?.isMobile,
  questionnaire: state.questionnaire,
  indicationName: state.profile.personal.condition,
  pageName: state.questionnaire.allQuestions[state.questionnaire.currentIndex]?.id,
  backdrop: state.backdrop,
  loggedIn: state?.authentication?.loggedIn,
  cameFrom: state.main.cameFrom,
  urlParameters: state.main.urlParameters,
  provider: state?.appConfiguration?.providerSpecific?.code,
  lastOpenedUrl: state?.main?.lastOpenedUrl,
});

export default connect(mapStateToProps)(StyledAppRoutes);
