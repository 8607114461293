const {
  NODE_ENV = 'production',
  REACT_APP_STAGE = 'production',
  REACT_APP_CALENDLY_WIDGET_ACCOUNT = 'clinicalteam',
  REACT_APP_CALENDLY_WIDGET_EVENT_NAME = 'exploring-clinical-trials-with-us',
  SENTRY_DSN = 'https://0a640b4fee8646b0a49def0289a684f5@sentry.io/1386228',
  PUBLIC_URL,
  PORT = 3000,
  TRIALS_LIMIT = 15,
  GOOGLE_MAP_REACT_KEY = 'AIzaSyDtbj7om0BfWdsMffDRk1nFSjV0dWc9qk8',
  REACT_APP_EXTERNAL_AUTH_GOOGLE_APP_ID = '520825349525-pr9153hni8e7ec4tsq8qlf539org4la0.apps.googleusercontent.com',
  REACT_APP_EXTERNAL_AUTH_FACEBOOK_APP_ID = '608167040531444',
  REACT_APP_RECAPTCHA_SITE_KEY = '',
  MAX_FILE_SIZE = 30,
  REACT_APP_CALENDLY_URL = 'https://calendly.com',
  SERVER_PUBLIC_URL = 'https://stage-webapi.trialjectory.com',
  ASSETS_PUBLIC_URL = 'https://webapp.leal.health',
  SPONSOR_ASSETS_PATH = '/assets/sponsors/',
  PROVIDER_ASSETS_PATH = '/assets/providers/',
  REACT_APP_GA_TRACKING_CODE = '',
  REACT_APP_INTERCOM_PRODUCTION_APP_ID = 'uusx3lmz',
  REACT_APP_INTERCOM_DEVELOPMENT_APP_ID = 'm1jjw7mq',
  REACT_APP_FACEBOOK_META_PRODUCTION_APP_ID = '',
  REACT_APP_FACEBOOK_META_DEVELOPMENT_APP_ID = '',
  REACT_APP_CRM_CHAT_KEY = '940cf68f-f118-4bec-a601-d5d47179fc48',
  REACT_APP_PREVENT_GA_TRACKING = '',
  REACT_APP_TOKEN_SECRET,
  REACT_APP_GEO_LOCATION_API,
} = process.env;

let recaptchaSiteKey = null;

let beApiUrl = 'https://stage-webapi.trialjectory.com';
let financeApiUrl = 'https://tj-fam-server-stage.leal.health';
let financeApiClientUrl = 'https://tj-fam-client-stage.leal.health';

const isLocal = REACT_APP_STAGE === 'local';

switch (REACT_APP_STAGE) {
  case 'production':
    beApiUrl = 'https://webapi.trialjectory.com';
    break;
  case 'local':
    beApiUrl = 'http://localhost:8082';
    if (process.env.REACT_APP_RECAPTCHA_LOCAL_SITE_KEY) recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_LOCAL_SITE_KEY;
    break;
  case 'preprod':
    beApiUrl = 'https://preprod-webapi.trialjectory.com';
    break;
  case 'aitagging':
    beApiUrl = 'https://aitagging-webapi.trialjectory.com';
    break;
  default:
    beApiUrl = 'https://stage-webapi.trialjectory.com';
    if (process.env.REACT_APP_RECAPTCHA_STAGE_SITE_KEY) recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_STAGE_SITE_KEY;
}

export default {
  nodeEnv: NODE_ENV,
  isProduction: REACT_APP_STAGE === 'production',
  isStage: REACT_APP_STAGE === 'stage',
  isLocal,
  preventGATracking: REACT_APP_PREVENT_GA_TRACKING,
  server: {
    port: PORT,
  },
  reactAppStage: REACT_APP_STAGE,
  beApiUrl,
  financeApiUrl,
  financeApiClientUrl,
  sentry: {
    dsn: isLocal ? null : SENTRY_DSN,
  },
  calendly: {
    account: REACT_APP_CALENDLY_WIDGET_ACCOUNT,
    eventName: REACT_APP_CALENDLY_WIDGET_EVENT_NAME,
    calendlyScriptSrc: 'https://assets.calendly.com/assets/external/widget.js',
    calendlySiteUrl: REACT_APP_CALENDLY_URL,
  },
  publicUrl: PUBLIC_URL,
  trialsLimit: TRIALS_LIMIT,
  googleMapReact: {
    bootstrapURLKeys: GOOGLE_MAP_REACT_KEY,
  },
  crmChat: {
    key: REACT_APP_CRM_CHAT_KEY,
  },
  externalAuth: {
    google: {
      appId: REACT_APP_EXTERNAL_AUTH_GOOGLE_APP_ID,
    },
    facebook: {
      appId: REACT_APP_EXTERNAL_AUTH_FACEBOOK_APP_ID,
    },
  },
  recaptchaSiteKey: recaptchaSiteKey || REACT_APP_RECAPTCHA_SITE_KEY,
  gaTrackingCode: REACT_APP_GA_TRACKING_CODE,
  maxFileSize: MAX_FILE_SIZE,
  publicServer: {
    publicUrl: SERVER_PUBLIC_URL,
    webappUrl: ASSETS_PUBLIC_URL,
    sponsorAssetsPath: SPONSOR_ASSETS_PATH,
    providerAssetsPath: PROVIDER_ASSETS_PATH,
  },
  intercomAppId: REACT_APP_STAGE === 'production' ? REACT_APP_INTERCOM_PRODUCTION_APP_ID : REACT_APP_INTERCOM_DEVELOPMENT_APP_ID,
  metaPixelId: REACT_APP_STAGE === 'production' ? REACT_APP_FACEBOOK_META_PRODUCTION_APP_ID : REACT_APP_FACEBOOK_META_DEVELOPMENT_APP_ID,
  tokenSecret: REACT_APP_TOKEN_SECRET,
  geosearchapi: REACT_APP_GEO_LOCATION_API,
};
