import React from 'react';
import configs from 'configs';
import app from 'new-ui/app';
import { useTranslation } from 'utils/modifiedTranslation';
import './Treatment.css';
import AiAssistant from 'new-ui/Components/AiAssistent';
import { Widgets } from './Widgets';
import { CustomerRefUserWidgets } from './CustomerRefUserWidgets';
import { FEED_GA } from './constants';

const { isLocal, isStage } = configs;

const FeedPage = ()=> {
  const { t } = useTranslation();

  const showEventsWidget = isLocal || isStage
    || new URLSearchParams(window.location.search).get('eventsWidget') === '1';
  const showCustomSurveyWidget = isLocal || isStage
    || new URLSearchParams(window.location.search).get('customSurveyWidget') === '1';

  const welcome = () => {
    return `${t('feed.title.main')}${app.user.info.family_name ? `, ${app.user.info.family_name}!` : ''}`;
  };

  const { personal: { customerRef } = {} } = app.user || {};

  return (
    <div className="feed-page">
      <h2 className="welcome welcome-mobile">{welcome()}</h2>
      <div className="feed-page-container">
        <h2 className="welcome">{welcome()}</h2>
        {customerRef ? (
          <CustomerRefUserWidgets
            showEventsWidget={showEventsWidget}
            showCustomSurveyWidget={showCustomSurveyWidget}
          />
        ) : (
          <Widgets
            showEventsWidget={showEventsWidget}
            showCustomSurveyWidget={showCustomSurveyWidget}
          />
        )}
      </div>
      <div className="feed-sidebar">
        <AiAssistant
          onUploadClick={()=>{
            app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.fileUploadClicked);
          }}
          onEditClick={()=>{
            app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.answerManuallyClicked);
          }}
        />
      </div>
    </div>
  );
};

export default FeedPage;
