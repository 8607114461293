/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import app from 'new-ui/app';
import './AddCaregiver.css';
import Form, { FORM_INPUT_TYPES } from 'new-ui/Components/Form';
import ArrowCircle from 'new-ui/assets/icons/arrow-circle-right.svg';
import { t, useTranslation } from 'utils/modifiedTranslation';
import { getInitialConditionProfileByIndication, signup } from 'new-ui/functions';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import { ALL_INDICATIONS, INDICATIONS } from 'new-ui/constants';
import { USER_TYPES, USER_TYPES_VALUES, PLATFORM } from 'new-ui/Components/QuestionRenderer/renderers/IntakeAboutYou';
import { AddCaregiverFooter } from './AddCaregiverFooter';
import { AddCaregiverHeader } from './AddCaregiverHeader';
import { AddCaregiverFormWrapper } from './AddCaregiverFormWrapper';
import { AddCaregiverWrapper } from './AddCaregiverWrapper';

const GA = {
  category: 'add_caregiver_popup',
  events: {
    error: 'add_caregiver popup signup error',
  },
};

export const GA_HCP = {
  category: 'HCP',
  events: {
    SIGNUP_SUCCESS: 'hcp signup succeed',
    SIGNUP_VIEWED: 'hcp signup viewed',
  },
};

const DEFAULT_INDICATION = INDICATIONS.AML;

const AddCaregiver = (props) => {
  const {
    onSignup,
    role = '',
    indication = DEFAULT_INDICATION,
    hideOrganization = false,
  } = props;
  const [form, setForm] = useState();
  const { recaptcha, getRecaptchaTokenAsync } = useRecaptcha();
  const [condition_profile, setConditionProfile] = useState(null);
  const [error, setError] = useState();
  const { t } = useTranslation();

  const {
    title = t('add_caregiver.title'),
    subtitle = t('add_caregiver.subtitle'),
  } = props;

  useEffect(()=>{
    const fetch = async ()=>{
      app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.SIGNUP_VIEWED);
      // const indications = await app.getIndications();
      const _condition_profile = await getInitialConditionProfileByIndication(DEFAULT_INDICATION);
      setConditionProfile(_condition_profile);
      const _form = {
        data: {
          organization_name: '',
          email: '',
          phone: '',
          role,
        },
        inputs: {
          organization_name: {
            type: FORM_INPUT_TYPES.INPUT,
            label: 'Organization',
          },
          role: {
            label: 'Role',
            type: FORM_INPUT_TYPES.RADIO,
            required: true,
            options: [
              {
                title: t('create_profile.role.medical_team'),
                value: USER_TYPES_VALUES[USER_TYPES.MEDICAL_TEAM],
              },
              {
                title: t('create_profile.role.patient_navigator'),
                value: USER_TYPES_VALUES[USER_TYPES.PATIENT_NAVIGATOR],
              },
              {
                title: t('create_profile.role.patient'),
                value: USER_TYPES_VALUES[USER_TYPES.PATIENT],
              },
              {
                title: t('create_profile.role.caregiver'),
                value: USER_TYPES_VALUES[USER_TYPES.CAREGIVER],
              },
              {
                title: t('create_profile.role.other'),
                value: USER_TYPES_VALUES[USER_TYPES.OTHER],
              },
            ],
          },
          email: {
            type: FORM_INPUT_TYPES.INPUT,
            required: true,
            icon: 'email',
            title: 'Email',
            placeholder: '',
            isRequired: true,
          },
          phone: {
            type: FORM_INPUT_TYPES.PHONE,
            icon: 'phone',
            title: 'Phone',
            placeholder: '651465',
            className: 'registration-phone',
          },
          // indication: {
          //   required: true,
          //   title: t('add_patient.indication_input_title'),
          //   type: FORM_INPUT_TYPES.INDICATION_CHOOSER,
          // },
        },
      };

      if (hideOrganization) delete _form.inputs.organization_name;

      setForm(_form);
    };

    fetch();
  }, []);

  const onChange = (_form)=>{
    console.log(_form);
  };

  const replaceInputTypes = () => {
    const inputElement = document.querySelector('.add-caregiver-form .form-part.email input');
    if (inputElement && inputElement.type === 'text') {
      inputElement.type = 'email';
    }
    const buttonElement = document.querySelector('.add-caregiver-form button');
    if (buttonElement && buttonElement.type === 'button') {
      buttonElement.type = 'submit';
    }
  };

  const isValidEmail = () => {
    const inputElement = document.querySelector('.add-caregiver-form .form-part.email input');
    return !!inputElement.validity.valid;
  };

  const register = async (event)=>{
    replaceInputTypes();

    if (!form.data.role) {
      setError(t('add_caregiver.error.role'));
    } else {
      setError('');
    }

    if (!isValidEmail()) {
      return;
    }

    if (!form.data.email || !form.data.role) {
      return;
    }

    app.setShowPreloader(true);
    signup({
      email: form.data.email,
      indication,
      recaptchaToken: await getRecaptchaTokenAsync(),
      condition_profile: await getInitialConditionProfileByIndication(DEFAULT_INDICATION),
      info: {
        // user_type: 'doctor',
        user_type: form.data.role,
        platform: PLATFORM.HCP,
        organization: form.data.organization_name,
      },
      personal: {
        providerId: window._provider || '',
        providerCode: window._provider || '',
      },
      onsignup: (user) => {
        console.log('user signup success :: addCaregiver', user);
        app.intercom.updateUserAttributes({
          email: form.data.email,
          user_type: `${PLATFORM.HCP}_${user?.info?.user_type}`,
          user_source: PLATFORM.HCP,
          organization: form.data.organization_name,
          condition: indication,
        }, user.info.user_wix_id);
        // app.sendGoogleAnalyticsEvent(GA.category, GA.events.success, { name: 'user_signup_success' });
        app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.SIGNUP_SUCCESS);
        app.setShowPreloader(false);
        onSignup(user);
      },
      onerror: (e) => {
        app.setShowPreloader(false);
        setError('Signup Error');
        app.sendGoogleAnalyticsEvent(GA.category, GA.events.error, { name: 'user_signup_error' });
      },
    });
  };

  return (
    <AddCaregiverWrapper>
      {recaptcha}
      {form ? (
        <>
          <AddCaregiverHeader
            title={t(title)}
            subtitle={t(subtitle)}
          />
          <AddCaregiverFormWrapper error={error}>
            <Form
              form={form}
              onChange={onChange}
              submit={{
                action: register,
                icon: ArrowCircle,
                title: window.t('general.save'),
              }}
            />
          </AddCaregiverFormWrapper>
          <AddCaregiverFooter />
        </>
      ) : null}
    </AddCaregiverWrapper>
  );
};

export default AddCaregiver;
