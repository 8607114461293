import React from 'react';
import './style.css';
import { EVENT_TYPES } from '../../options';
import TreatmentFeedback from './TreatmentFeedback';
import StatusButton from './StatusButton';
import { useTranslation } from 'utils/modifiedTranslation';

const ExpiredEvent = ({ item, onClickStatusBtn, onClickMoodFeedback }) => {
  const { t } = useTranslation();
  const { type, date, cycle } = item;

  const handleFeedback = (id, emoji) => {
    onClickMoodFeedback(id, emoji);
  };

  return (
    <div className='expired-event'>
      <div className='details'>
        <span className='bullet' style={{ backgroundColor: EVENT_TYPES[type].color }}></span>
        <span className='date'>{date}</span>
        <span className='label' style={{ backgroundColor: EVENT_TYPES[type].color }}>{t(EVENT_TYPES[type].label)}</span>
        {cycle && <span className='cycle'>{t('events_widget.list.cycle.label')} {cycle}</span>}
      </div>

      <div className='actions'>
          {type === 'treatment' && <TreatmentFeedback treatment={item} onSelect={handleFeedback} />}
          {type === 'clinic_visit' && <TreatmentFeedback treatment={item} onSelect={handleFeedback} />}
          {type === 'scan' && <StatusButton onClick={onClickStatusBtn} title={t('events_widget.button.status')} />}
      </div>
    </div>
  );
};

export default ExpiredEvent;