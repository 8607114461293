import { REDUX_ACTIONS, OPENED_VIA, THEME } from './constants';

export const toggleDialog = (dialogName, theme = THEME.STANDARD, props = {}, openedVia = OPENED_VIA.REDUX_ACTION) => ({
  type: REDUX_ACTIONS.DIALOG_TOGGLE,
  payload: {
    openedVia,
    dialogName,
    theme,
    props,
  },
});

export const setDialogData = (payload) => ({
  type: REDUX_ACTIONS.DIALOG_DATA_SET,
  payload,
});

export const clearDialogData = () => ({
  type: REDUX_ACTIONS.DIALOG_DATA_CLEAR,
});
