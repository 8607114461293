import configs from 'configs';
import request from 'functions/api/request';
import { authHeaders } from 'functions/authHeader';

const { beApiUrl: apiUrl } = configs;

export async function getCustomSurvey({ surveyTarget }) {
  const requestOptions = {
    url: `${apiUrl}/survey/customSurvey/${surveyTarget}`,
    headers: await authHeaders(),
    json: true,
  };

  try {
    const { success, data } = await request.get(requestOptions);
    if (!success) {
      throw new Error('something went wrong');
    }
    return data;
  } catch (ex) {
    console.error('Error occurred, while getting custom survey:', ex);
    throw ex;
  }
}

export async function setCustomSurvey({ surveyTarget, ...payload }) {
  const requestOptions = {
    url: `${apiUrl}/survey/customSurvey/${surveyTarget}`,
    headers: await authHeaders(),
    data: payload,
    json: true,
  };

  try {
    const { success } = await request.post(requestOptions);
    if (!success) {
      throw new Error('something went wrong');
    }
  } catch (ex) {
    console.error('Error occurred, while setting custom survey:', ex);
    throw ex;
  }
}
