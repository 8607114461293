import React from 'react';
import PropTypes from 'prop-types';
import './WidgetSectionDelimiterStyles.css';

export const WidgetSectionDelimiter = ({ text }) => (
  <div className="feed-page-section">
    <span>
      {text}
    </span>
  </div>
);

WidgetSectionDelimiter.propTypes = {
  text: PropTypes.string.isRequired,
};
