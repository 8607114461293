import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { AddCaregiverHeader } from '../AddCaregiver/AddCaregiverHeader';
import { AddCaregiverFooter } from '../AddCaregiver/AddCaregiverFooter';
import { AddCaregiverWrapper } from '../AddCaregiver/AddCaregiverWrapper';
import useRecaptcha from '../../../utils/hooks/useRecaptcha';
import { AddCaregiverFormWrapper } from '../AddCaregiver/AddCaregiverFormWrapper';
import Form, { FORM_INPUT_TYPES } from '../../Components/Form';
import ArrowCircle from '../../assets/icons/arrow-circle-right.svg';
import isEmailValid from '../../../functions/isEmailValid';
import app from '../../app';
import { getInitialConditionProfileByIndication, signup } from '../../functions';
import { PLATFORM } from '../../Components/QuestionRenderer/renderers/IntakeAboutYou';
import { GA } from '../../Pages/Feed/EventsWidget';
import { isPhoneValid } from '../../../functions/formValidations/isPhoneValid';
import { GA_HCP } from '../../Search/GA';

const getForm = (indication, t) => ({
  data: {
    email: '',
    phone: '',
    role: 'patient',
    indication,
  },
  inputs: {
    email: {
      type: FORM_INPUT_TYPES.INPUT,
      required: true,
      icon: 'email',
      title: t('hcp_signup.form.input.email'),
    },
    phone: {
      type: FORM_INPUT_TYPES.PHONE,
      icon: 'phone',
      title: t('hcp_signup.form.input.phone'),
      placeholder: '',
    },
    indication: {
      required: true,
      title: t('add_patient.indication_input_title'),
      type: FORM_INPUT_TYPES.INDICATION_CHOOSER,
    },
  },
});

const validateForm = (form, t) => {
  if (!isEmailValid(form.data.email)) {
    return t('hcp_signup.form.error.email');
  }

  if (!form.data.role) {
    return t('hcp_signup.form.error.role');
  }

  if (form.data.phone && !isPhoneValid(form.data.phone)) {
    return t('hcp_signup.form.error.phone');
  }

  if (!form.data.indication) {
    return t('hcp_signup.form.error.indication');
  }
};

export const SignupHCP = (props) => {
  const {
    title = 'hcp_signup.patient.title',
    subtitle = 'hcp_signup.patient.subtitle',
    indication,
    profile,
    onSignup,
  } = props;

  const [form, setForm] = useState(null);
  const [formError, setFormError] = useState('');
  const { recaptcha, getRecaptchaTokenAsync } = useRecaptcha();
  const { t } = useTranslation();

  useEffect(() => {
    setForm(getForm(indication, t));
  }, [indication, t]);

  const handleOnSubmit = async () => {
    const validationError = validateForm(form, t);

    if (validationError) {
      return setFormError(validationError);
    }

    app.setShowPreloader(true);

    await signup({
      email: form.data.email,
      indication,
      recaptchaToken: await getRecaptchaTokenAsync(),
      condition_profile: {
        ...profile?.condition_profile ?? await getInitialConditionProfileByIndication(indication),
      },
      info: {
        ...profile?.info ?? {},
        user_type: form.data.role,
        platform: PLATFORM.HCP,
        email: form.data.email,
      },
      personal: {
        ...profile?.personal ?? {},
        providerId: window._provider || '',
        providerCode: window._provider || '',
      },
      onsignup: (user) => {
        app.intercom.updateUserAttributes(
          {
            email: form.data.email,
            user_type: `${PLATFORM.HCP}_${user?.info?.user_type}`,
            user_source: PLATFORM.HCP,
            organization: form.data.organization_name,
            condition: indication,
          },
          user.info.user_wix_id,
        );

        app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.PATIENT_SIGNUP_SUCCESS);
        app.setShowPreloader(false);

        onSignup(user);
      },
      onerror: (e) => {
        console.error(e);
        app.setShowPreloader(false);
        app.sendGoogleAnalyticsEvent(GA.category, GA.events.error, { name: 'user_signup_error' });
      },
    });
  };

  return (
    <AddCaregiverWrapper>
      {recaptcha}
      <AddCaregiverHeader
        title={(
          <div style={{ lineHeight: 'normal' }}>
            {t(title)}
          </div>
        )}
        subtitle={(
          <div style={{ whiteSpace: 'pre-line', textAlign: 'left' }}>
            {t(subtitle)}
          </div>
        )}
      />
      <AddCaregiverFormWrapper error={formError}>
        <Form
          form={form}
          submit={{
            action: handleOnSubmit,
            icon: ArrowCircle,
            title: t('general.save'),
          }}
        />
      </AddCaregiverFormWrapper>
      <AddCaregiverFooter />
    </AddCaregiverWrapper>
  );
};

SignupHCP.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.any,
  indication: PropTypes.string.isRequired,
  profile: PropTypes.object,
  onSignup: PropTypes.func,
};
