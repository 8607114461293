import React from 'react';
import PropTypes from 'prop-types';

export const AddCaregiverWrapper = ({ children }) => {
  return (
    <div className="add-caregiver">
      {children}
    </div>
  );
};

AddCaregiverWrapper.propTypes = {
  children: PropTypes.any.isRequired,
};
