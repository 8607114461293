import React, { useState, useEffect, useCallback } from 'react';
import './style.css';
import { Button } from '@material-ui/core';
import { plusCircle, search, searchActive } from 'utils/static/icons';
import app from 'new-ui/app';
import { PAGES, URI } from 'new-ui/constants';
import { useTranslation } from 'utils/modifiedTranslation';
import CustomCheckbox from '../CustomCheckbox';
import { createUserFinance } from '..';

const FinanceCategorySelect = ({
  options, onSelectionChange, meta, showAll, maxItems, GA,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [visibleOptions, setVisibleOptions] = useState([]);
  const [extraItemsCount, setExtraItemsCount] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDomReady, setDomReady] = useState(false);

  const { t } = useTranslation();

  const updateOptions = useCallback(() => {
    if (showAll) {
      setVisibleOptions(options);
      setExtraItemsCount(0);
      setDomReady(true);
      return;
    }

    if (maxItems) {
      const itemsToShow = Math.min(maxItems, options.length);
      setVisibleOptions(options.slice(0, itemsToShow));
      setExtraItemsCount(options.length - itemsToShow);
      setDomReady(true);
      return;
    }

    const container = document.querySelector('.accordion-collapsed .finance-checkboxes');
    if (container) {
      const checkboxes = document.querySelectorAll('.accordion-collapsed .finance-checkbox');
      const containerStyles = window.getComputedStyle(container);
      const containerWidth = container.clientWidth
                + parseFloat(containerStyles.paddingLeft)
                + parseFloat(containerStyles.paddingRight)
                + parseFloat(containerStyles.borderLeftWidth)
                + parseFloat(containerStyles.borderRightWidth)
                + parseFloat(containerStyles.marginLeft)
                + parseFloat(containerStyles.marginRight);

      let totalWidth = 0;
      let itemsPerRow = 0;

      for (let i = 0; i < checkboxes.length; i++) {
        const checkbox = checkboxes[i];
        const checkboxStyles = window.getComputedStyle(checkbox);
        const checkboxFullWidth = checkbox.clientWidth
                    + parseFloat(checkboxStyles.paddingLeft)
                    + parseFloat(checkboxStyles.paddingRight)
                    + parseFloat(checkboxStyles.borderLeftWidth)
                    + parseFloat(checkboxStyles.borderRightWidth)
                    + parseFloat(checkboxStyles.marginLeft)
                    + parseFloat(checkboxStyles.marginRight);

        totalWidth += checkboxFullWidth;

        if (totalWidth <= containerWidth) {
          itemsPerRow++;
        } else if (totalWidth > containerWidth) {
          itemsPerRow--;
          totalWidth -= checkboxFullWidth;
          break;
        }
      }

      setVisibleOptions(options.slice(0, itemsPerRow));
      setExtraItemsCount(options.length - itemsPerRow);
      setDomReady(true);
    }
  }, [options, showAll, maxItems]);

  useEffect(() => {
    if (options && options.length) {
      const initiallySelectedOptions = options.filter((option) => option.selected);
      setSelectedOptions(initiallySelectedOptions);
      setVisibleOptions(options);
      setTimeout(() => {
        updateOptions();
      }, 0);
    }
  }, [options, updateOptions]);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleCheckboxChange = (option) => {
    const newSelectedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];

    setSelectedOptions(newSelectedOptions);
    onSelectionChange(newSelectedOptions);
  };
  return (
    <div
      className={`finance-category ${isDomReady ? 'ca-show' : 'ca-hide'} ${showAll ? 'show-all' : ''} ${maxItems ? 'max-items' : ''}`}
    >
      <h3 className="title">{t('general.interested_in')}</h3>
      <div className="accordion">
        {!expanded ? (
          <div className="accordion-collapsed">
            <div className="finance-checkboxes">
              {visibleOptions.map((option, index) => (
                <div key={index} className="finance-checkbox">
                  <div className="icon"><img src={option.icon} alt="icon" /></div>
                  <CustomCheckbox
                    label={option.label}
                    onChange={() => handleCheckboxChange(option)}
                    checked={selectedOptions.includes(option)}
                    GA={GA}
                  />
                </div>
              ))}
            </div>
            {(extraItemsCount > 0) && (
              <div className="category-info">
                <div className="category-count">
                  +
                  {extraItemsCount}
                </div>
                <Button onClick={handleExpand} className="expand-btn">
                  <img className="info-icon" src={plusCircle} alt="plus circle" />
                  <span className="info-label">Open</span>
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div className="accordion-expanded">
            <div className="finance-checkboxes">
              {options.map((option, index) => (
                <div key={index} className="finance-checkbox">
                  <div className="icon"><img src={option.icon} alt="icon" /></div>
                  <CustomCheckbox
                    label={option.label}
                    onChange={() => handleCheckboxChange(option)}
                    checked={selectedOptions.includes(option)}
                    GA={GA}
                  />
                </div>
              ))}
              <Button className="collapse-btn" onClick={handleExpand}>
                <span className="label">Less</span>
              </Button>
            </div>
          </div>
        )}
      </div>

      <Button className="check-btn">
        <img className="search" alt="icon" src={search} />
        <img className="search-active" alt="icon" src={searchActive} />
        <span
          className="check-label"
          onClick={()=> {
            app.sendGoogleAnalyticsEvent(GA.category, GA.events.clickEligibility, { name: 'Financial support check eligibility clicked' });
            if ((app.isCurrentPage(PAGES.FINANCE) && !app.user) || (app.isCurrentPage(PAGES.PUBLIC_FINANCE) && app.isPatientWithoutId())) {
              createUserFinance({
                redirectTo: URI[PAGES.FINANCE_INTAKE],
                subtitle: t('support_programs.create_user_subtitle_eligibility'),
              });
              return true;
            }
            // app.history.push(`${URI[PAGES.FINANCE_INTAKE]}?hcp=1`);
            if (app.user && (!app.user.info.user_type || app.user.info.user_type === 'patient')) {
              app.history.push(`${URI[PAGES.FINANCE_INTAKE]}`);
            } else {
              app.history.push(`${URI[PAGES.FINANCE_INTAKE]}?hcp=1`);
            }
          }}
        >
          {`Check eligibility to all ${meta?.program_count} programs`}
        </span>
      </Button>

    </div>
  );
};

export default FinanceCategorySelect;
