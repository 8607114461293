import React from 'react';
import footerGFX from '../../assets/hipaa.svg';

export const AddCaregiverFooter = () => {
  return (
    <div className="add-caregiver-footer">
      <div className="footer-gfx">
        <img src={footerGFX} alt="text" />
      </div>
      <div className="add-caregiver-agreement">
        {'By clicking "Save" I agree to the '}
        <a className="signup-page-link-color" href="https://www.leal.health/terms" target="_blank" rel="noreferrer">Terms</a>
        {' and '}
        <a className="signup-page-link-color" href="https://www.leal.health/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
        {' . '}
        <br />
        {' This site is protected by reCAPTCHA and the '}
        <a className="signup-page-link-color" href="https://policies.google.com/privacy" target="_blank" rel="noreferrer"> Google Privacy Policy </a>
        {' and '}
        <a className="signup-page-link-color" href="https://policies.google.com/terms" target="_blank" rel="noreferrer"> Term of Service </a>
        {' apply. '}
      </div>
    </div>
  );
};
