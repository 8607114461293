import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'utils/modifiedTranslation';
import { CustomSurvey, getCustomSurvey, setCustomSurvey } from 'components/CustomSurvey';
import Preloader from 'new-ui/Components/Preloader';
import {
  PRISTINE, PENDING, FAILURE, SUCCESS,
} from 'utils/constants/queryStatuses';
import AssistanceButton from '../AssistanceButton';
import styles from './customSurveyWidget.module.css';

const SURVEY_TARGET = 'trialExperience';

export const CustomSurveyWidget = () => {
  const { t } = useTranslation();
  const { lang } = useSelector((state) => ({
    lang: state?.main?.lang,
  }));
  const [survey, setSurvey] = useState({ status: PRISTINE, data: null });
  const [answers, setAnswers] = useState({ status: PRISTINE });

  const { status: surveyStatus, data: surveyData } = survey;
  const { status: answersStatus } = answers;

  const {
    surveyConfigurationId, formConfiguration, variables, surveyKey,
  } = surveyData ?? {};

  useEffect(() => {
    const shouldFetch = surveyStatus === PRISTINE
      || (surveyStatus === SUCCESS && answersStatus === SUCCESS);

    if (!shouldFetch) {
      return;
    }

    (async () => {
      try {
        setSurvey({ status: PENDING, data: null });
        setAnswers({ status: PRISTINE });

        const result = await getCustomSurvey({ surveyTarget: SURVEY_TARGET });

        setSurvey({ status: SUCCESS, data: result });
      } catch (ex) {
        console.error('Error occurred, while getting custom survey config', ex);
        setSurvey({ status: FAILURE, data: null });
      }
    })();
  }, [surveyStatus, answersStatus]);

  const handleSubmit = useCallback(async (answers, options) => {
    try {
      // non-zero length string
      // we need to have completedHtml in order showSaveError works
      // we need to call it in order to override default completedHtml
      options.showSaveInProgress(' ');
      setAnswers({ status: PENDING });
      await setCustomSurvey({
        surveyTarget: SURVEY_TARGET, surveyConfigurationId, surveyKey, answers,
      });
      setAnswers({ status: SUCCESS });
    } catch (ex) {
      console.error('Error occurred, while saving custom survey answers', ex);
      options.showSaveError();
      setAnswers({ status: FAILURE });
    }
  }, [surveyConfigurationId, surveyKey]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h2 className={styles.headerTitle}>
          {t('feed_custom_survey_widget.title')}
        </h2>
        <h3 className={styles.headerSubTitle}>
          {t('feed_custom_survey_widget.sub_title')}
        </h3>
      </div>
      <div className={styles.body}>
        {(surveyStatus === PENDING || answersStatus === PENDING) && <Preloader isVisible />}
        {!!surveyData && (
          <CustomSurvey
            json={formConfiguration}
            variables={variables}
            onComplete={handleSubmit}
            lang={lang}
            t={t}
            surveyConfigurationId={surveyConfigurationId}
          />
        )}
        {!surveyData && surveyStatus === SUCCESS && (
          <div className={styles.completedState}>
            <h3>
              {t('feed_custom_survey_widget.completed_state')}
            </h3>
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <AssistanceButton title={t('feed_custom_survey_widget.assistance_button')} />
      </div>
    </div>
  );
};
