import React, { useState, useRef } from 'react';
import { useTranslation } from 'utils/modifiedTranslation';
import CustomSelect from '../CustomSelect';
import Button from 'new-ui/Components/Button';
import './style.css';
import { EVENT_TYPES, REPEAT_OPTIONS } from '../../options';

const EventAddForm = ({ initialEventType = '', onAddEvent }) => {
  const { t } = useTranslation();

  const monthRef = useRef(null);
  const dayRef = useRef(null);
  const yearRef = useRef(null);

  const [formData, setFormData] = useState({
    month: '',
    day: '',
    year: '',
    cycle: '',
    eventType: initialEventType,
    repeatStatus: 'no_repeat'
  });

  const [errors, setErrors] = useState({
    month: false,
    day: false,
    year: false,
    cycle: false,
    eventType: !!initialEventType,
    repeatStatus: true
  });

  const [errorVisibility, setErrorVisibility] = useState(false);

  const isLeapYear = (year) => {
    return (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0));
  };

  const isValidMonth = (month) => {
    return month >= 1 && month <= 12;
  };

  const isValidDay = (day, month, year) => {
    const daysInMonth = [
      31, // January
      isLeapYear(year) ? 29 : 28, // February (considering leap year)
      31, // March
      30, // April
      31, // May
      30, // June
      31, // July
      31, // August
      30, // September
      31, // October
      30, // November
      31, // December
    ];

    return day >= 1 && day <= daysInMonth[month - 1];
  };

  const isValidYear = (year) => {
    return [2025, 2026, 2027, 2028, 2029].includes(Number(year));
  };

  const validateFormData = (data) => {
    const errors = {};
    errors.month = !isValidMonth(data.month);
    errors.day = !isValidDay(data.day, data.month, data.year);
    errors.year = !isValidYear(data.year);
    // errors.cycle = !data.cycle;
    errors.eventType = !data.eventType;
    return errors;
  };

  const handleChange = (name, value) => {
    const newFormData = { ...formData, [name]: value };
    setFormData(newFormData);
    setErrors(validateFormData(newFormData));
  
    if (newFormData.month && newFormData.day && newFormData.year && newFormData.year.length === 4) {
      setErrorVisibility(true);
    }
  };

  const handleMonthChange = (e) => {
    let value = e.target.value;
  
    if (/^\d{0,2}$/.test(value)) {
      if (parseInt(value, 10) > 12) {
        value = '12';
      }
      handleChange(e.target.name, value);
  
      if (value.length === 2) {
        dayRef.current.focus();
      }
    }
  };

  const handleDayChange = (e) => {
    let value = e.target.value;
  
    if (/^\d{0,2}$/.test(value)) {
      if (parseInt(value, 10) > 31) {
        value = '31';
      }
      handleChange(e.target.name, value);
  
      if (value.length === 2) {
        yearRef.current.focus();
      }
    }
  };  

  const handleYearChange = (e) => {
    let value = e.target.value;
  
    if (/^\d{0,4}$/.test(value)) {
      if (value.length > 4) {
        value = value.slice(0, 4);
      }
      handleChange(e.target.name, value);
    }
  };

  const handleCycleChange = (e) => {
    let value = e.target.value;
  
    if (/^\d{0,2}$/.test(value)) {
      if (parseInt(value, 10) > 99) {
        value = '99';
      }
      handleChange(e.target.name, value);
    }
  };
  
  const isFormValid = ({ cycle, ...restFormData }) => {
    return Object.values(restFormData).every((value) => value.trim() !== '') && Object.values(errors).every((error) => !error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid(formData)) {
      console.log('Event Data:', formData);
      onAddEvent(formData);
    } else {
      console.log('Please fill all fields');
    }
  };

  const arrangeTypeOptions = () => {
    return Object.values(EVENT_TYPES).map(option => {
      option.value = option.key;
      option.label = t(option.label);
      return option;
    });
  }; 

  const arrangeRepeatOptions = (options) => {
    return options.map(option => {
      option.label = t(option.label);
      return option;
    });
  };

  return (
    <form onSubmit={handleSubmit} className='event-add-form'>
      <div className='form-group-wrapper' style={{ borderColor: (errors.month || errors.day || errors.year) ? '#EB4B62' : '#FF8C69' }}>
        <div className='form-group form-group--month' style={{ borderColor: errors.month ? '#EB4B62' : '#FF8C69' }}>
          <input
            type="number"
            inputMode="numeric"
            id="month"
            name="month"
            value={formData.month}
            placeholder={t('events_widget.placeholder.month')}
            onChange={handleMonthChange}
            maxLength={2}
            ref={monthRef}
          />
        </div>
        <div className='form-group form-group--day' style={{ borderColor: errors.day ? '#EB4B62' : '#FF8C69' }}>
          <input
            placeholder={t('events_widget.placeholder.day')}
            type="number"
            inputMode="numeric"
            id="day"
            name="day"
            value={formData.day}
            onChange={handleDayChange}
            maxLength={2}
            ref={dayRef} 
          />
        </div>
        <div className='form-group form-group--year' style={{ borderColor: errors.year ? '#EB4B62' : '#FF8C69' }}>
          <input
            placeholder={t('events_widget.placeholder.year')}
            type="number"
            inputMode="numeric"
            id="year"
            name="year"
            value={formData.year}
            onChange={handleYearChange}
            maxLength={4}
            ref={yearRef}
          />
        </div>
      </div>

      <CustomSelect
        label="Select"
        className={'event-select'}
        options={arrangeTypeOptions()}
        selectedValue={formData.eventType}
        onSelect={(value) => handleChange('eventType', value)} 
      />

      {formData.eventType == 'treatment' && (
        <div className='form-group form-group--cycle' style={{ borderColor: errors.cycle ? '#EB4B62' : '#FF8C69' }}>
          <input
            type="number"
            inputMode="numeric"
            id="cycle"
            name="cycle"
            placeholder={t('events_widget.placeholder.cycle')}
            value={formData.cycle}
            onChange={handleCycleChange}
          />
        </div>
      )}

      <CustomSelect
        label="Repeat status"
        className={'status-select'}
        options={arrangeRepeatOptions(REPEAT_OPTIONS)}
        selectedValue={formData.repeatStatus}
        onSelect={(value) => handleChange('repeatStatus', value)} 
      />

      <Button
        backgroundColor="#213332"
        color="#FFF"
        width="75px"
        className="add-btn"
        title={t('events_widget.button.add')}
        action={handleSubmit}
        disabled={!isFormValid(formData)}
      />

      {(errorVisibility && (errors.month || errors.day || errors.year)) && (
        <div className="error-feedback">
          {errors.month || errors.day
            ? t('events_widget.error.date')
            : errors.year
            ? t('events_widget.error.year')
            : null}
        </div>
      )}

    </form>
  );
};

export default EventAddForm;