import React, { useState, useRef, useEffect } from 'react';
import './style.css';
import Radio from 'new-ui/Components/Form/Radio';
import Button from 'new-ui/Components/Button';
import { useTranslation } from 'utils/modifiedTranslation';

import { getSurvey, setSurvey } from 'new-ui/Popups/TreatmentSurvey/api';
import { SURVEYS } from 'new-ui/constants';
import app from 'new-ui/app';

import LottieSuccessFeedback from 'new-ui/assets/lottie/new_results.json';
import { getLottie, getOverlay } from 'new-ui/functions';
import ArrowButton from '../ArrowButton';
import TreatmentIntensity from './TreatmentIntensity';
import AssistanceButton from '../AssistanceButton';
import WidgetBtn from '../Button';
import { FEED_GA } from '../constants';

let $loading;

const ExperienceWidgetV2 = ({
  onAddExperance, onChangeSelectedDrug, selectedDrug, onClickPreviousTreatment,
}) => {
  const { t } = useTranslation();
  const [reset, setReset] = useState(0);
  // const [data, setData] = useState(null);
  const [sideEffects, setSideEffects] = useState(null);
  // const [experiences, setExperiences] = useState(null);
  const [drugs, setDrugs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentDrugIndex, setCurrentDrugIndex] = useState(0);
  const [aggression, setAggression] = useState(null);
  const [sideEffect, setSideEffect] = useState(null);
  const [freeText, setFreeText] = useState('');
  const [currentDrug, setCurrentDrug] = useState(null);
  const [answers, _setAnswers] = useState([]);
  const [patientsCount, setPatientsCount] = useState(0);

  useEffect(()=>{
    app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.surveyViewed, {
      widget: 'treatment survey',
    });
    const fetch = async () => {
      setIsLoading(true);
      const experiences = await getSurvey(SURVEYS.TREATMENT_EXPERIENCE, app.user.info.user_wix_id);
      const sideEffects = experiences.data.data.sideEffects.map((sideEffect) => ({
        title: t(`side_effects.${sideEffect.key}`),
        name: sideEffect.key,
        value: sideEffect.id,
      }));
      const { drugs } = experiences.data.data;
      // setData(experiences);
      // setExperiences(experiences.data);
      setSideEffects(sideEffects);
      setDrugs(drugs);
      setPatientsCount(experiences.data.data.patients_with_same_indication);
      // const drug = selectedDrug || drugs[currentDrugIndex];
      const drug = selectedDrug || drugs[drugs.length - 1];

      setCurrentDrug(drug);
      const answers = experiences.data.answers || [];
      _setAnswers(answers);
      setIsLoading(false);
    };
    fetch();
    // eslint-disable-next-line
    },[])

  const radioApi = useRef({});

  const triggerReset = () => {
    setReset((prev) => prev + 1);
    setAggression(null);
    setFreeText('');
    setSideEffect(null);
    radioApi.current.setValue(null);
  };

  useEffect(() => {
    const drug = selectedDrug || drugs[currentDrugIndex];
    setCurrentDrug(drug);
    // eslint-disable-next-line
    },[selectedDrug])

  useEffect(() => {
    const drug = drugs[currentDrugIndex];
    // const drug = selectedDrug || drugs[currentDrugIndex];
    if (drug) {
      setCurrentDrug(drug);
      onChangeSelectedDrug(drug);
    }
    // eslint-disable-next-line
    },[currentDrugIndex])

  const onBtnClick = async () => {
    onClickPreviousTreatment();
  };

  const onClickDone = async () => {
    if (sideEffect) {
      // setAnswers(drug.name, 'side_effects', sideEffect);
      for (const effect of sideEffect) {
        setAnswers(currentDrug.name, 'side_effects', effect.name);
      }
    }
    if (aggression) {
      setAnswers(currentDrug.name, 'aggression', aggression);
    }
    if (freeText) {
      setAnswers(currentDrug.name, 'side_effects_free_text', freeText);
    }

    app.setShowPreloader(true);
    await setSurvey(SURVEYS.TREATMENT_EXPERIENCE, answers, app.user.info.user_wix_id);
    triggerReset();

    app.setShowPreloader(false);
    showSuccessFeedback();
    onAddExperance(currentDrug);
  };

  const showSuccessFeedback = () => {
    $loading = getOverlay();
    const $lottie = getLottie(LottieSuccessFeedback);
    $loading.append($lottie);
    document.body.appendChild($loading);
    $loading.show();
    setTimeout(() => {
      $loading.remove();
    }, 2000);
  };

  const setAnswers = (surveyKey, key, values = []) => {
    let i = answers.length;

    // If the key is not 'side_effects', remove previous answers for that key.
    while (i > 0) {
      i -= 1;
      const answer = answers[i];
      if (answer.surveyKey === surveyKey && answer.key === key && key !== 'side_effects') {
        answers.splice(i, 1);
      }
    }

    if (typeof values === 'string') {
      // If key is 'side_effects', allow multiple values by pushing without removing
      if (key === 'side_effects') {
        answers.push({ surveyKey, key, value: values });
      } else {
        answers.push({ surveyKey, key, value: values });
      }
    } else {
      values.forEach((value) => {
        answers.push({ surveyKey, key, value });
      });
    }
  };

  // const setAnswers = (surveyKey, key, values = []) => {
  //     let i = answers.length;

  //     while (i > 0) {
  //       i -= 1;
  //       const answer = answers[i];
  //       if (answer.surveyKey === surveyKey && answer.key === key) answers.splice(i, 1);
  //     }

  //     if (typeof values === 'string') {
  //       answers.push({ surveyKey, key, value: values });
  //     } else {
  //       values.forEach((value) => {
  //         answers.push({ surveyKey, key, value });
  //       });
  //     }
  // };

  const onChangeAggression = (value) => {
    setAggression(value);
  };

  const onChangeSideEffect = (values) => {
    const effects = [];
    for (const value of values) {
      const effect = sideEffects.find((e) => e.value === value);
      effects.push(effect);
    }
    setSideEffect(effects);
  };

  const onChangeSideEffectRadio = (value) => {
    setSideEffect(value);
  };

  const onChangeFreeText = (event) => {
    setFreeText(event.target.value);
  };

  const onNext = () => {
    setCurrentDrugIndex((prevIndex) => (prevIndex + 1) % drugs.length);
    app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.surveyInteracted, {
      widget: 'treatment survey',
    });
  };

  const onPrev = () => {
    setCurrentDrugIndex((prevIndex) => (prevIndex - 1 + drugs.length) % drugs.length);
    app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.surveyInteracted, {
      widget: 'treatment survey',
    });
  };

  const getDrugName = () => {
    let names = '';
    if (currentDrug) {
      names = currentDrug.name.split('+').map((name) => t(`questionnaire.questions.drugs_received.${name}`));
      names = names.join(' + ');
    }
    return names;
  };

  return (
    <>
      {!isLoading && (
        <div className="experience-v2-widget">
          <div className={`widget-header ${drugs.length > 0 ? 'arrows' : ''}`}>
            <ArrowButton direction="left" onClick={onPrev} />
            <ArrowButton direction="right" onClick={onNext} />
            <div className="details">
              <div className="label">{getDrugName()}</div>
            </div>
          </div>
          <div className="widget-body">
            <h2 className="title">{t('experience_widget.title')}</h2>
            <h3 className="sub-title">
              {`${t('experience_widget.help_patients.p1')} ${patientsCount} ${t('experience_widget.help_patients.p2')}`}
            </h3>

            {drugs.length > 1 && (
              <>
                <ArrowButton direction="left" onClick={onPrev} />
                <ArrowButton direction="right" onClick={onNext} />
              </>
            )}

            <TreatmentIntensity
              sideEffects={sideEffects}
              onChangeAggression={onChangeAggression}
              onChangeSideEffect={onChangeSideEffect}
              reset={reset}
            />

            <div className="radios">
              <Radio
                options={[{ value: 'no_sideeffects', title: t('experience_widget.no_side_effect') }]}
                value={null}
                api={radioApi.current}
                onChange={onChangeSideEffectRadio}
              />
            </div>

            <div className="overview-container">
              <h2 className="title">{t('experience_widget.overview.title')}</h2>
              <textarea value={freeText} onChange={onChangeFreeText} placeholder={t('experience_widget.textarea.placeholder')} />
            </div>

            <div className="actions">
              <Button
                backgroundColor="#FFF"
                color="#FF8C69"
                width="250px"
                className="btn btn-done"
                title={t('treatment_widget.actions.done.button')}
                action={onClickDone}
              />
              <WidgetBtn title={t('experience_widget.actions.add_treatment.button')} onClick={onBtnClick} />
            </div>
          </div>
          <div className="widget-footer">
            <AssistanceButton title={t('experience_widget.actions.assistance.button')} onClick={()=>{}} />
          </div>
        </div>
      )}
    </>
  );
};

export default ExperienceWidgetV2;
