import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
// import { useTranslation } from 'react-i18next';

// import app from 'new-ui/app';

import Button from 'new-ui/Components/Button';
import BackButton from 'new-ui/Components/BackButton';
import Form, { FORM_INPUT_TYPES, FORM_VALIDATORS } from 'new-ui/Components/Form';

import VerificationInput from 'react-verification-input';

import { authenticationActions } from 'components/Authentication/actions';
import { authenticationService } from 'components/Authentication/api';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import phone from 'phone';
import { useTranslation } from '../../../utils/modifiedTranslation';
import { extractNetworkErrorTranslationKey } from '../../../functions/extractNetworkErrorTranslationKey';

const OTP_TYPE = {
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
};

const phoneForm = {
  inputs: {
    phone: {
      type: FORM_INPUT_TYPES.PHONE,
      placeholder: 'Your Phone',
    },
  },
  data: {
    phone: null,
  },
};

const emailForm = {
  inputs: {
    email: {
      type: FORM_INPUT_TYPES.INPUT,
      placeholder: 'Your Email',
      icon: 'email',
    },
  },
  data: {
    email: '',
  },
};

let verificationCode = null;

const checkSession = (item) => !!item && item !== 'null';

const OTPLogin = () => {
  const { t } = useTranslation();
  const [otpType, setOtptype] = useState(OTP_TYPE.EMAIL);
  const [showError, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [showVerification, setShowVerification] = useState(false);
  const { recaptcha, getRecaptchaTokenAsync } = useRecaptcha();
  phoneForm.inputs.phone.placeholder = t('login.your_phone');
  emailForm.inputs.email.placeholder = t('login.your_email');
  const { phoneConfirmationResponse, error } = useSelector((state) => ({
    phoneConfirmationResponse: state?.authentication?.phoneConfirmationResponse,
    error: state?.authentication?.error,
  }));
  const phoneQueryType = new URLSearchParams(window.location.search).get('type');

  const dispatch = useDispatch();

  useEffect(() => {
    // in case user already exists in sign up, navigate to login page.
    if (phoneQueryType && phoneQueryType === OTP_TYPE.PHONE) {
      localStorage.removeItem('filledProfileData');
    }
  }, [phoneQueryType]);

  useEffect(() => {
    const $input = document.querySelector('.vi');
    if ($input) {
      $input.setAttribute('type', 'tel');
      $input.setAttribute('pattern', '[0-9]*');
      $input.setAttribute('inputmode', 'numeric');
    }
  }, [showVerification]);

  useEffect(() => {
    if (phoneConfirmationResponse && phoneConfirmationResponse.smsUserId) {
      setShowVerification(true);
    }
  }, [phoneConfirmationResponse]);

  useEffect(() => {
    setError(error ? t(error) : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const renderAction = () => {
    if (otpType === OTP_TYPE.PHONE) return t('login.send_to_my_email_instead');
    return t('login.send_to_my_phone_instead');
  };

  const actionClick = () => {
    setError(null);
    if (otpType === OTP_TYPE.PHONE) return setOtptype(OTP_TYPE.EMAIL);
    setOtptype(OTP_TYPE.PHONE);
  };

  const submitPhone = async (data) => {
    let phoneNumber;

    if ((!data.phone || data.phone.length < 5) && !checkSession(localStorage.masked_phone)) {
      return setError(t('login.phone_is_missing'));
    }

    setError(null);
    if (data.phone) {
      if (!data.phone.startsWith('+')) {
        phoneNumber = `+${data.phone}`;
      } else {
        phoneNumber = data.phone;
      }
    }

    if (phone(phoneNumber).isValid) {
      phoneNumber = phone(phoneNumber).phoneNumber;
    }

    const requestData = checkSession(localStorage.masked_phone) ? {
      type: 'phone',
      ...JSON.parse(localStorage.enc),
    } : {
      phone: phoneConfirmationResponse && phoneConfirmationResponse.smsUserId
        ? phoneConfirmationResponse.phone
        : phoneNumber,
    };

    const response = await authenticationService.passwordlessLogin({
      ...requestData,
      recaptchaToken: await getRecaptchaTokenAsync(),
    });

    if (!response.success) {
      return setError(t(extractNetworkErrorTranslationKey(response) || 'authentication.error.error_while_send_otp'));
    }
    phoneForm.data.phone = response.phone;
    setShowVerification(true);
  };

  const submitEmail = async (data) => {
    if (!data.email && !checkSession(localStorage.masked_email)) {
      return setError(t('login.email_is_missing'));
    }
    if (!FORM_VALIDATORS.EMAIL(data.email) && !checkSession(localStorage.masked_email)) {
      return setError(t('login.invalid_email_address'));
    }
    const requestData = checkSession(localStorage.masked_email) ? {
      type: 'email',
      ...JSON.parse(localStorage.enc),
    }
      : {
        email: data.email,
      };
    const response = await authenticationService.passwordlessLogin({
      ...requestData,
      recaptchaToken: await getRecaptchaTokenAsync(),
    });
    if (!response.success) {
      return setError(t(extractNetworkErrorTranslationKey(response) || 'authentication.error.error_while_send_otp'));
    }
    emailForm.data.email = response.email;
    setShowVerification(true);
    setError(null);
  };

  const submit = async (isResend, data) => {
    setSuccessMessage('');
    try {
      if (otpType === OTP_TYPE.PHONE) {
        await submitPhone(data);
      } else {
        await submitEmail(data);
      }
      if (isResend) {
        setSuccessMessage('post_login.code_on_way');
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  const submitVerification = async () => {
    let phoneNumber;
    if (phoneForm.data.phone && !phoneForm.data.phone.startsWith('+')) {
      phoneNumber = `+${phoneForm.data.phone}`;
    } else {
      phoneNumber = phoneForm.data.phone;
    }

    if (phone(phoneNumber).isValid) {
      phoneNumber = phone(phoneNumber).phoneNumber;
    }
    const loginRequestData = otpType === OTP_TYPE.PHONE ? { phone: phoneNumber } : { email: emailForm.data.email };
    const code = Object.values(verificationCode).join('');
    const data = phoneConfirmationResponse && phoneConfirmationResponse.smsUserId
      ? phoneConfirmationResponse
      : loginRequestData;
    const isSingUp = !!(phoneConfirmationResponse && phoneConfirmationResponse.smsUserId);
    try {
      const res = await dispatch(authenticationActions.sendVerificationCode({
        verificationCode: code,
        recaptchaToken: await getRecaptchaTokenAsync(),
        ...data,
      }, isSingUp, new URLSearchParams(window.location.search).get('redirect')));
      if (typeof res === 'string') {
        setError(t('authentication.error.wrong_code'));
      }
    } catch (ex) {
      setError('Something went wrong');
    }
  };

  const verificationChange = (data) => {
    setError(null);
    if (data && String(data).length === 4) {
      verificationCode = data;
      submitVerification();
    }
  };

  const getMasked = () => {
    const phone = checkSession(localStorage.masked_phone) ? localStorage.masked_phone : phoneForm.data.phone;
    const email = checkSession(localStorage.masked_email) ? localStorage.masked_email : emailForm.data.email;
    return otpType === OTP_TYPE.PHONE ? phone : email;
  };

  const onVerificationInputBlur = () => {
    const $input = document.querySelector('.verification-character');
    if ($input) {
      $input.click();
    }
  };

  return (
    <div className="otp">
      {recaptcha}
      {
        !showVerification
          ? (
            <div className="otp-input login-form">
              <div className="header">
                <h1>
                  {t('login.quick_login_with_one_time_code')}
                </h1>
              </div>
              <div className="input-container">
                {otpType === OTP_TYPE.PHONE
                  ? (
                    <div className="input-phone">
                      {checkSession(localStorage.masked_phone)
                        ? (
                          <div className="approve-text">
                            {t('login.well_send_you_a_code_to')}
                            :
                            <div className="masked">
                              <b>{getMasked()}</b>
                            </div>
                          </div>
                        )
                        : (
                          <Form
                            form={phoneForm}
                          />
                        )}
                    </div>
                  ) : (
                    <div className="input-email">
                      {checkSession(localStorage.masked_email)
                        ? (
                          <div className="approve-text">
                            {t('login.well_send_you_a_code_to')}
                            :
                            <div className="masked">
                              <b>{getMasked()}</b>
                            </div>
                          </div>
                        ) : (
                          <Form
                            form={emailForm}
                          />
                        )}
                    </div>
                  )}
                <Button
                  title={t('login.next')}
                  action={() => submit(false, otpType === OTP_TYPE.PHONE ? phoneForm.data : emailForm.data)}
                />
                {showError ? <div className="login-error">{showError}</div> : null}
                <div className="action">
                  <span onClick={actionClick}>
                    {
                      renderAction()
                    }
                  </span>
                </div>
              </div>
            </div>
          )
          : (
            <div className="otp-verify">
              <div className="back-holder">
                <BackButton action={() => {
                  dispatch(authenticationActions.resetFormValidity());
                  dispatch(authenticationActions.resetPhoneConfirmationResponse());
                  localStorage.removeItem('filledProfileData');
                  setShowVerification(false);
                }}
                />
              </div>
              <div className="login-form">
                <div className="title">
                  {t('login.verification_code')}
                </div>
                <div className="text">
                  {t('login.enter_the_one_time_code')}
                  {' '}
                  <span>{getMasked()}</span>
                </div>
                {showError ? <div className="login-error">{showError}</div> : null}
                <div className="input">
                  <VerificationInput
                    length={4}
                    autoFocus
                    placeholder=""
                    validChars="0-9"
                    onChange={verificationChange}
                    onBlur={onVerificationInputBlur}
                    classNames={{
                      container: 'verification-container',
                      character: 'verification-character',
                      characterInactive: 'verification-character-inactive',
                      characterSelected: 'verification-character-selected',
                    }}
                  />
                  {successMessage ? <span>{t(successMessage)}</span> : null}
                </div>
              </div>
              <div className="verify-action">
                {t('login.didnt_receive_a_code')}
                {' '}
                <span
                  className="inline-button"
                  onClick={() => submit(true, otpType === OTP_TYPE.PHONE ? phoneForm.data : emailForm.data)}
                >
                  {t('login.send_again')}
                </span>
              </div>
              <style>{'.login-options{ display:none; }'}</style>
            </div>
          )
      }
    </div>
  );
};

export default OTPLogin;
