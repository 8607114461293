export const ROUTE_NAME = {
  HOME: 'HOME',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  QUESTIONNAIRE: 'QUESTIONNAIRE',
  QUESTIONNAIRE_QUESTIONS: 'QUESTIONNAIRE_QUESTIONS',
  MATCHES: 'MATCHES',
  MATCHES_DETAILS: 'MATCHES_DETAILS',
  MATCHES_MAP: 'MATCHES_MAP',
  TRIAL: 'TRIAL',
  TRIALS: 'TRIALS',
  SIGN_UP: 'SIGN_UP',
  AFTER_SIGNUP_USER_DETAILS: 'AFTER_SIGNUP_USER_DETAILS',
  SEND_TO_CLINICAL_TEAM: 'SEND_TO_CLINICAL_TEAM',
  TREATMENT_FINDER: 'TREATMENT_FINDER',
  SPONSOR: 'SPONSOR',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  CREATE_PASSWORD: 'CREATE_PASSWORD',
  NEW_PASSWORD: 'NEW_PASSWORD',
  TRIAL_FINDER: 'TRIAL_FINDER',
  GENETIC_TESTING: 'GENETIC_TESTING',
  SUMMARY: 'SUMMARY',
  RESULTS: 'RESULTS',
  TRIAL_NEW: 'TRIAL_NEW', // todo: change
  MEDICAL_FILES: 'MEDICAL_FILES',
  INTAKE: 'INTAKE',
  FEED: 'FEED',
};

export const ROUTE_PATH = {
  [ROUTE_NAME.HOME]: '/',
  [ROUTE_NAME.LOGIN]: '/login',
  [ROUTE_NAME.SUMMARY]: '/dashboard/summary',
  [ROUTE_NAME.RESULTS]: '/dashboard/results',
  [ROUTE_NAME.FEED]: '/dashboard/feed',
  [ROUTE_NAME.TRIAL_NEW]: '/dashboard/trial/:nctNumber',
  [ROUTE_NAME.LOGOUT]: '/logout',
  [ROUTE_NAME.QUESTIONNAIRE_QUESTIONS]: '/questionnaire/questions/:questionsId',
  [ROUTE_NAME.QUESTIONNAIRE]: '/questionnaire/:questionId',
  [ROUTE_NAME.MATCHES]: '/matches',
  [ROUTE_NAME.MATCHES_MAP]: '/matches/map',
  [ROUTE_NAME.MATCHES_DETAILS]: '/matches/trial/:nctNumber',
  [ROUTE_NAME.TREATMENT_FINDER]: '/matches/treatment-finder',
  [ROUTE_NAME.SIGN_UP]: '/signup',
  [ROUTE_NAME.AFTER_SIGNUP_USER_DETAILS]: '/after-signup-user-details',
  [ROUTE_NAME.INTAKE]: '/intake',
  [ROUTE_NAME.SEND_TO_CLINICAL_TEAM]: '/sendToClinicalTeam',
  [ROUTE_NAME.SPONSOR]: '/sponsor/:nctId',
  [ROUTE_NAME.TRIAL]: '/trial/:cancerType/:nctId',
  [ROUTE_NAME.SPONSOR]: '/sponsor/:nctId',
  [ROUTE_NAME.FORGOT_PASSWORD]: '/login/forgot-password',
  [ROUTE_NAME.CREATE_PASSWORD]: '/login/create-password',
  [ROUTE_NAME.NEW_PASSWORD]: '/login/reset-password',
  [ROUTE_NAME.TRIALS]: '/trials/:cancerType?',
  [ROUTE_NAME.SPONSOR]: '/sponsor/:nctId',
  [ROUTE_NAME.TRIAL_FINDER]: '/trial-finder/:cancerType?',
  [ROUTE_NAME.GENETIC_TESTING]: '/genetic-testing',
  [ROUTE_NAME.MEDICAL_FILES]: '/medical-files',
};

export const ROUTES_TO_HIDE_BY_CONDITION = {
  [ROUTE_NAME.HOME]: [],
  [ROUTE_NAME.LOGIN]: [],
  [ROUTE_NAME.LOGOUT]: [],
  [ROUTE_NAME.QUESTIONNAIRE]: [],
  [ROUTE_NAME.MATCHES]: [],
  [ROUTE_NAME.MATCHES_DETAILS]: [],
  [ROUTE_NAME.SIGN_UP]: [],
  [ROUTE_NAME.SEND_TO_CLINICAL_TEAM]: [],
  [ROUTE_NAME.TREATMENT_FINDER]: [],
  [ROUTE_NAME.MATCHES_MAP]: [],
  [ROUTE_NAME.FORGOT_PASSWORD]: [],
  [ROUTE_NAME.CREATE_PASSWORD]: [],
  [ROUTE_NAME.NEW_PASSWORD]: [],
};

export const REDIRECT_TO_ACTIONS = {
  EVENTS: {
    SIGN_UP: 'signUp',
    QUESTIONNAIRE: 'questionnaire',
    LOGIN: 'login',
    EDIT_QUESTIONNAIRE: 'editQuestionnaire',
  },
  SET_AFTER: 'SET_AFTER',
  RESET_AFTER: 'RESET_AFTER',
  SET_AFTER_SIGN_UP: 'SET_AFTER_SIGN_UP',
  RESET_AFTER_SIGN_UP: 'RESET_AFTER_SIGN_UP',
  SET_AFTER_QUESTIONNAIRE: 'SET_AFTER_QUESTIONNAIRE',
  RESET_AFTER_QUESTIONNAIRE: 'RESET_AFTER_QUESTIONNAIRE',
  SET_AFTER_LOGIN: 'SET_AFTER_LOGIN',
  RESET_AFTER_LOGIN: 'RESET_AFTER_LOGIN',
  SET_AFTER_EDITING_QUESTIONNAIRE: 'SET_AFTER_EDITING_QUESTIONNAIRE',
  RESET_AFTER_EDITING_QUESTIONNAIRE: 'RESET_AFTER_EDITING_QUESTIONNAIRE',
};

export const SET_GLOBAL_ERROR = 'SET_GLOBAL_ERROR';
export const SET_CMP_REF_TO_PRINT = 'SET_CMP_REF_TO_PRINT';
