/* eslint-disable global-require */
import React from 'react';
import PropTypes from 'prop-types';
import { useStyleSheetInjection } from 'utils/hooks/useStyleSheetInjection';
import PhoneInputField from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { getDefaultUserCountry } from 'components/Authentication/functions';
// eslint-disable-next-line import/no-unresolved, import/no-webpack-loader-syntax
import phoneInputCSS from '!!raw-loader!./PhoneInput.css';

const PhoneInput = (props) => {
  const { country, onChange, onenter } = props;

  useStyleSheetInjection(phoneInputCSS);

  return (
    <div className="phone-input">
      <PhoneInputField
        country={country || getDefaultUserCountry() || 'us'}
        specialLabel=""
        buttonClass="phone-input-dropdown"
        onEnterKeyPress={() => {
          if (onenter) onenter();
        }}
        onChange={onChange}
      />
      <img className="icon" src={require('../../../assets/icons/phone.svg')} alt="" />
    </div>
  );
};

PhoneInput.propTypes = {
  country: PropTypes.any,
  onChange: PropTypes.func,
  onenter: PropTypes.func,
};

export default PhoneInput;
