import React, { useState } from 'react';
import { useTranslation } from 'utils/modifiedTranslation';
import Button from 'new-ui/Components/Button';
import ArrowLeft from 'new-ui/assets/icons/arrow_left_2.svg';
import Preloader from 'new-ui/Components/Preloader';
import './style.css';
import { STATUSES } from '../options';
import { updateStatus } from '../api';
import app from 'new-ui/app';
import cx from 'classnames';

const StatusCards = ({ onClickBack }) => {
  const { t } = useTranslation();
  const [showPreloader, setShowPreloader] = useState(false);

  const handleStatusChange = async (status) => {
    setShowPreloader(true);
    try {
      await updateStatus(status);
      setShowPreloader(false);
    } catch (error) {
      console.error('Error changeing status:', error);
    } finally {
      setShowPreloader(false);
    }
  };

  return (
    <div className='status-cards'>
      <ul className='status-list'>
      {STATUSES.map((status, index) => {
          const isSelected = app?.user?.condition_profile?.disease_status === status.value;
          return (
            <li
              key={index}
              className={cx({ 'selected-status': isSelected })}
              onClick={() => handleStatusChange(status.value)}
            >
              <img className="icon" src={status.icon} alt="icon" />
              <span className="label">{t(status.label)}</span>
            </li>
          );
        })}
      </ul>

      <Button
        backgroundColor="#F2F2F7"
        color="rgba(33, 51, 50, 0.80)"
        width="initial"
        className="back-btn"
        title={t('events_widget.button.back')}
        action={onClickBack}
        icon={ArrowLeft}
      />

      <Preloader isVisible={showPreloader} />
    </div>
  );
};

export default StatusCards;