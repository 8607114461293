import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './IndicationChooser.css';
import app from 'new-ui/app';
import { useTranslation } from 'utils/modifiedTranslation';
import SearchIcon from 'new-ui/assets/icons/search_text.svg';
import CloseIcon from 'new-ui/assets/icons/close.svg';

const IndicationChooser = (props) => {
  const { onChange, value } = props;

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [showDropDown, setShowDropDown] = useState(false);
  const [indications, setIndications] = useState([]);
  const [currentIndications, setCurrentIndications] = useState([]);
  const [currentIndication, setCurrentIndication] = useState(null);
  const [currentValue, setCurrentValue] = useState('');

  const containerRef = useRef(null);

  useEffect(() => {
    const fetch = async () => {
      const indications = (await app.getIndications()).map((indication) => ({
        title: indication.title.indexOf('disease_name') >= 0 ? t(indication.title) : indication.title,
        value: indication.value,
      }));

      const indication = indications.find((a) => a.value === value);

      setIndications(indications);
      setCurrentIndication(indication);
      setCurrentValue(indication?.title ?? '');
      setIsLoading(false);
    };

    fetch();
  }, []);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setCurrentValue(currentIndication?.title ?? '');
        setShowDropDown(false);
        onChange(currentIndication?.value ?? '');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, currentIndication]);

  const handleCleanup = () => {
    setCurrentIndications([...indications]);
    setCurrentIndication(null);
    setCurrentValue('');
    setShowDropDown(false);
    onChange('');
  };

  const handleIndicationSelect = (indication) => {
    setCurrentIndication(indication);
    setCurrentValue(indication?.title ?? '');
    setShowDropDown(false);
    onChange(indication?.value ?? '');
  };

  const handleValueChange = (e) => {
    const { value } = e.target;

    const currentIndications = indications.filter((indication) => {
      return indication?.title?.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    });

    setCurrentValue(value);
    setCurrentIndications(currentIndications);
    setShowDropDown(true);
  };

  return indications?.length ? (
    <div
      className={`indication-chooser-input ${showDropDown ? 'indication-chooser-input-show' : ''}`}
      ref={containerRef}
    >
      <input
        value={currentValue}
        onChange={handleValueChange}
        placeholder="Start typing indication..."
        disabled={isLoading}
      />
      {currentIndication ? (
        <img
          className="indication-chooser-close-icon"
          src={CloseIcon}
          alt="icon"
          onClick={handleCleanup}
        />
      ) : <img className="indication-chooser-list-icon" src={SearchIcon} alt="icon" />}
      <div className="indication-chooser-items">
        {currentIndications.length
          ? currentIndications.map((indication) => (
            <div
              className="indication-chooser-item"
              onClick={() => handleIndicationSelect(indication)}
              key={indication.value}
            >
              {indication?.title ?? ''}
            </div>
          ))
          : <div>{t('indication_chooser.no_results')}</div>}
      </div>
    </div>
  ) : null;
};

IndicationChooser.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default IndicationChooser;
