/**
 * Concats current search param to path
 *
 * @param {String} rootPath URL string
 * @returns {String} URL string with location search parameter
 *
 * @example <caption>Example usage</caption>
 *  // if the current url is '/treatment-finder?dialog=learnMore'
 *  preserveQueryParam('/matches') // '/matches?dialog=learnMore'
 */
export default (rootPath, { omit, extra, pick } = {}) => {
  if (!omit && !extra && !pick) {
    return rootPath + window.location.search;
  }

  const searchParams = new URLSearchParams(window.location.search);
  (omit ?? []).forEach((name) => {
    searchParams.delete(name);
  });
  (extra ?? []).forEach(([name, value]) => {
    searchParams.set(name, value);
  });

  if (pick?.length) {
    Array.from(searchParams.keys()).forEach((key) => {
      if (!pick.includes(key)) {
        searchParams.delete(key);
      }
    });
  }
  const searchString = searchParams.toString();

  if (!searchString) {
    return rootPath;
  }
  return `${rootPath}?${searchString}`;
};
