import React, { useState, useEffect } from 'react';
import './PublicationSection.css';
import fetchPublicationData from './api/api'; // Import the API function
import './Spinner.css'; // Import the spinner CSS
// eslint-disable-next-line import/order
import PropTypes from 'prop-types';

const PublicationSection = ({ publicationsData }) => {
  const [publications, setPublications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadPublications = async () => {
      try {
        if (publicationsData) {
          // If publicationsData is provided, use it directly
          setPublications(publicationsData.slice(0, 10));
          setLoading(false);
        } else {
          // Otherwise, fetch data from the API
          const data = await fetchPublicationData();
          setPublications(data.publications.slice(0, 10));
        }
      } catch (err) {
        setError(err);
      } finally {
        if (!publicationsData) {
          setLoading(false);
        }
      }
    };

    loadPublications();
  }, [publicationsData]);

  if (loading) {
    return (
      <div className="publication-section">
        <h2>Recent publications</h2>
        <div className="spinner-container">
          <div className="spinner" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="publication-section">
        <h2>Recent publications</h2>
        <div>
          Error loading publications:
          {error.message}
        </div>
      </div>
    );
  }

  // Conditionally render the section if there are publications
  if (publications.length === 0) {
    return null;
  }

  return (
    <div className="publication-section">
      <h2>Recent publications</h2>
      {publications.map((publication, index) => (
        <div className="publication-item" key={index}>
          <div className="publication-source">
            <span className="source">{publication.source}</span>
            <span className="date">{publication.articleDate}</span>
          </div>
          <div className="publication-title">
            <a href={publication.link} target="_blank" rel="noopener noreferrer">
              {publication.paperTitle}
            </a>
          </div>
          <div className="publication-summary">{publication.summaryForPatient}</div>
        </div>
      ))}
    </div>
  );
};

PublicationSection.propTypes = {
  publicationsData: PropTypes.array,
};

export default PublicationSection;
