import React, { Suspense } from 'react';

export { getCustomSurvey, setCustomSurvey } from './api';

export const CustomSurveyComponent = React.lazy(() => import('./CustomSurvey'));

export const CustomSurvey = (props) => (
  <Suspense fallback={null}>
    <CustomSurveyComponent {...props} />
  </Suspense>
);
