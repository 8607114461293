import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'utils/modifiedTranslation';
import { WidgetSectionDelimiter } from './WidgetSectionDelimiter/WidgetSectionDelimiter';
import { CustomSurveyWidget } from './CustomSurveyWidget/CustomSurveyWidget';
import EventsWidget from './EventsWidget';

export const CustomerRefUserWidgets = ({ showEventsWidget, showCustomSurveyWidget }) => {
  const { t } = useTranslation();
  return (
    <>
      <WidgetSectionDelimiter text={t('feed.sections.treatment.title')} />
      {showEventsWidget && <EventsWidget />}
      {showCustomSurveyWidget && (
        <>
          <br />
          <CustomSurveyWidget />
        </>
      )}
    </>
  );
};

CustomerRefUserWidgets.propTypes = {
  showEventsWidget: PropTypes.bool.isRequired,
  showCustomSurveyWidget: PropTypes.bool.isRequired,
};
