import * as queryString from 'query-string';
import { USER_TYPES, USER_TYPES_VALUES } from '../Components/QuestionRenderer/renderers/IntakeAboutYou';

export const getSearchParamsFromRoute = (qs = window.location.search) => {
  let { user_type: userType = USER_TYPES.OTHER } = queryString.parse(qs);

  if (!USER_TYPES_VALUES[userType]) {
    userType = USER_TYPES.OTHER;
  }

  return {
    userType,
  };
};

export const isPatientFromParams = () => {
  const { userType } = getSearchParamsFromRoute();
  return userType === USER_TYPES.PATIENT;
};
