import { DOM } from 'new-ui/functions';
import './Accordion.css';
import { isArray } from 'lodash';

export class Accordion {
  constructor(settings = {}) {
    const DEFAUTS = {
      $element: document.body,
      className: '',
      onrender: ()=>{},
      items: [],
    };

    this.settings = Object.assign(DEFAUTS, settings);

    this.$element = this.settings.$element;
    this.items = this.settings.items;

    if (this?.settings?.className && this.$element) {
      this.$element.classList.add(this?.settings?.className);
    }

    this.render();
  }

  render() {
    if (this.settings.onbeforerender) this.settings.onbeforerender(this);

    this.$element.innerHTML = '';

    this.$accordion = DOM('.accordion', {
      '/.accordion-items': {
        name: 'items',
      },
    });

    if (this.settings.className) this.$accordion.classList.add(this.settings.className);

    const { $items } = this.$accordion;

    this.items.forEach((item)=>{
      const $item = DOM('.accordion-item', {
        '/.accordion-item-head': {
          name: 'head',
        },
        '/.accordion-item-body': {
          name: 'body',
        },
      });

      if (item.className) {
        if (isArray(item.className)) {
          item.className.forEach((c)=>{
            $item.classList.add(c);
          });
        } else {
          const p = item.className.split(' ');
          p.forEach((c)=>{
            if (c) $item.classList.add(c);
          });
        }
      }

      item.$item = $item;
      $item.item = item;
      $item.accordion = this;

      item.show = ()=>{
        $item.classList.remove('accordion-item-collapsed');
        item.collapsed = false;
      };

      item.hide = ()=>{
        $item.classList.add('accordion-item-collapsed');
        item.collapsed = true;
      };

      item.toggle = ()=>{
        $item.classList.toggle('accordion-item-collapsed');
        item.collapsed = !item.collapsed;
      };

      if (item.collapsed) item.hide();

      item.render = ()=>{
        item.renderHead();
        item.renderBody();
      };

      item.renderHead = ()=>{
        $item.$head.innerHTML = '';
        if (item.head) item.head(item, $item.$head);
      };

      item.renderBody = ()=>{
        $item.$body.innerHTML = '';
        if (item.body) item.body(item, $item.$body);
        else if (item.items) {
          item.items.forEach((i)=>{
            i.parent = item;
            if (item.childHead) i.head = item.childHead;
            if (item.childBody) i.body = item.childBody;
          });
          item.accordion = new Accordion({
            $element: $item.$body,
            items: item.items,
          });
        }
      };

      if (item.hidden) return;

      $items.appendChild($item);

      item.render();
    });
    this.$element.appendChild(this.$accordion);
    if (this.settings.onrender) this.settings.onrender();
  }
}
