/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import './HeaderTabs.css';
import { ReactComponent as SupportIcon } from 'new-ui/assets/icons/support.svg';
import { ReactComponent as TreatmentIcon } from 'new-ui/assets/icons/treatment_icon.svg';
import NewIcon from 'new-ui/assets/icons/new.svg';
import { ReactComponent as HouseIcon } from 'new-ui/assets/icons/house.svg';
import { ReactComponent as TinaIcon } from 'new-ui/assets/icons/tina.svg';
import { PAGES, QUERY_PARAMS, URI } from 'new-ui/constants';
import app from 'new-ui/app';
import { RESULT_LIST_TABS } from 'new-ui/Results/ResultList';
import { GA_HCP } from 'new-ui/Search/GA';
import { useTranslation } from 'utils/modifiedTranslation';
import { createUserFinance } from 'new-ui/Pages/Finance';
import { isHcp as _isHcp, isSearchPage as _isSearchPage } from '.';
import preserveQueryParam from '../../functions/preserveQueryParam';

const GA = {
  category: 'Finance',
  events: {
    clickFinancialTab: 'Clicking on the “Financial support” tab',
    clickTreatmentTab: 'Clicking on the “Treatment options” tab',
  },
};

const GA_CHAT = {
  category: 'Chat',
  events: {
    clickSpeakWithTinaTab: 'Clicking on the “Speak with Tina” tab',
  },
};

const HeaderTabs = (props)=>{
  const { preSelectedTab = 'results' } = props;
  const [isOverlayActive, setIsOverlayActive] = useState(false);

  const isHcp = _isHcp();
  const isSearchPage = _isSearchPage();

  const { t } = useTranslation();

  const gotoTab = (tabName)=>{
    if (app.isCurrentPage(PAGES.RESULTS)) app.setTab(tabName);
    else app.history.push(`${URI[PAGES.RESULTS]}?tab=${tabName}`);
  };

  const { personal: { customerRef } = {} } = app.user || {};

  return (
    <div className={`header-tabs ${isHcp ? 'hcp-header-tabs' : 'patient-header-tabs'}`}>
      {!isHcp && (
        <div
          className={`header-tab ${app.isCurrentPage(PAGES.FEED) ? 'header-tab-selected' : ''} header-tab-feed`}
          onClick={()=>{
            app.history.push(URI[PAGES.FEED]);
            app.sendGoogleAnalyticsEvent(GA.category, GA.events.clickFinancialTab, { name: 'Feed tab clicked' });
          }}
        >
          <div className="header-tab-icon">
            <HouseIcon />
          </div>
          <div className="header-tab-title">{t('general.feed')}</div>
          <img className="header-tab-new" src={NewIcon} alt="none" />
        </div>
      )}
      {!customerRef && (
        <div
          className={`header-tab ${
            (isSearchPage || isHcp)
              ? app.isCurrentPage(PAGES.SEARCH)
                ? 'header-tab-selected'
                : ''
              : app.isCurrentPage(PAGES.RESULTS) && preSelectedTab === 'results'
                ? 'header-tab-selected'
                : ''
          } header-tab-results`}
          onClick={()=>{
            if (app.isCurrentPage(PAGES.FINANCE) && !app.user) {
              createUserFinance({
                redirectTo: `${URI[PAGES.RESULTS]}?rematch=1`,
                subtitle: t('support_programs.create_user_subtitle_header'),
              });
              return;
            }
            if (isSearchPage || isHcp) {
              app.history.push(preserveQueryParam(URI[PAGES.SEARCH], {
                pick: [QUERY_PARAMS.USER_TYPE],
              }));
              app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.TREATMENTS_TAB_CLICKED);
            } else gotoTab(RESULT_LIST_TABS.TRIALS);
          }}
        >
          <div className="header-tab-icon">
            <TreatmentIcon />
          </div>
          <div
            className="header-tab-title"
            onClick={()=>{
              app.sendGoogleAnalyticsEvent(GA.category, GA.events.clickTreatmentTab, { name: 'Treatment options tab' });
            }}
          >
            {t('general.treatment_options')}
          </div>
        </div>
      )}
      <div
        className={`header-tab ${
          (isSearchPage || isHcp)
            ? app.isCurrentPage(PAGES.PUBLIC_FINANCE)
              ? 'header-tab-selected'
              : ''
            : app.isCurrentPage(PAGES.FINANCE)
              ? 'header-tab-selected'
              : ''
        } header-tab-finance`}
        onClick={()=>{
          if (isSearchPage || isHcp) {
            app.history.push(preserveQueryParam(URI[PAGES.PUBLIC_FINANCE], {
              pick: [QUERY_PARAMS.USER_TYPE],
            }));
            app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.FINANCE_TAB_CLICKED);
          } else app.history.push(URI[PAGES.FINANCE]);
          app.sendGoogleAnalyticsEvent(GA.category, GA.events.clickFinancialTab, { name: 'Financial support tab clicked' });
        }}
      >
        <div className="header-tab-icon">
          <SupportIcon />
        </div>
        <div className="header-tab-title">{t('general.support_programs')}</div>
        <img className="header-tab-new" src={NewIcon} alt="none" />
      </div>
      {!(isSearchPage || isHcp) && (
        <div
          className={`header-tab ${app.isCurrentPage(PAGES.CHAT) ? 'header-tab-selected' : ''} header-tab-chat`}
          onClick={()=>{
            app.sendGoogleAnalyticsEvent(GA_CHAT.category, GA_CHAT.events.clickSpeakWithTinaTab, { name: 'speack with tina tab clicked' });
            app.history.push(URI[PAGES.CHAT]);
          }}
        >
          <div className="header-tab-icon">
            <TinaIcon />
          </div>
          <div className="header-tab-title">{t('general.speak_with_tina')}</div>
        </div>
      )}
    </div>
  );
};

export default HeaderTabs;
