import React, { useState, useEffect } from 'react';
import './Trial.css';

import { useSelector } from 'react-redux';

import Action from 'new-ui/Widgets/Action';
import TrialItem from 'new-ui/Trial/Item';
import ClosestTrial from 'new-ui/Trial/Closest';
import TrialDetails from 'new-ui/Trial/Details';
import BackButton from 'new-ui/Components/BackButton';

import {
  ACTION_TYPES,
  POPUP_TYPES,
} from 'new-ui/constants';

import { fetchSponsoredTrialTemplateData } from 'modules/sponsoredTrialTemplate/api';
import { getTrialByNCT } from 'new-ui/api';

import { useParams } from 'react-router-dom';
import app from 'new-ui/app';
import WhatsNext from './WhatsNext';
import { publicItemHeader } from './PublicItemHeader';

export const GA_HCP = {
  category: 'HCP',
  events: {
    TRIAL_VIEWED: 'hcp trial viewed',
  },
};

const PublicTrial = () => {
  const { nctNumber } = useParams();
  const [sponsoredTemplate, setSponsoredTemplate] = useState({});
  const [trial, setTrial] = useState(null);
  const [trialHeader, setTrialHeader] = useState(null);

  window.setEligibilityHeader = setTrialHeader;

  const { lang } = useSelector((state) => ({
    lang: state?.main?.lang,
  }));

  app.trial = trial; // debugging purposes

  useEffect(()=>{
    app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.TRIAL_VIEWED);
    document.body.classList.add('hide-ineligible-header');
    publicItemHeader({
      setHeader: setTrialHeader,
      item: trial,
    });
    return ()=>{
      app.setHeader(null);
      const $b = document.querySelector('.eligibility-header-back');
      if ($b?.parentElement) $b.parentElement.removeChild($b);
      document.body.classList.remove('eligibility-body');
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const usersCountry = JSON.parse(localStorage.getItem('searchCountryLocation')) ?? app.user?.info?.country?.location;
      const cancerType = app.hcpUser ? app.hcpUser?.personal?.condition : app?.user?.personal?.condition;
      const trial_ = await getTrialByNCT({
        nctNumber, 
        usersCountry, 
        conditions: {
          cancerType
      }});
      setTrial(trial_);
    };
    if (!trial) fetch();
  }, [nctNumber, trial]);

  useEffect(() => {
    const getSponsoredTemplate = async () => {
      const facilityName = trial?.closest_facility?.facility_name;
      const conditions = {
        isMobile: false,
        isForPDF: false,
        lang,
        country: app?.user?.info?.country?.name || '',
        countryStates: app?.user?.info?.country?.state ? [app?.user?.info?.country?.state] : undefined,
        facilityNames: facilityName ? [facilityName] : undefined,
        cancerType: app?.user?.personal?.condition || '',
        nctNumbers: [trial.nct_number],
      };
      const r = await fetchSponsoredTrialTemplateData({
        nctId: trial.nct,
        conditions,
        nctNumber: nctNumber?.toUpperCase(),
        indication: app?.user?.personal?.condition || '',
      });

      app._lastFetchedSponsored = r;

      if (r?.result?.length) {
        const template = {};
        r.result.forEach((item) => {
          if (String(item.blockName).startsWith('WhatNext')) {
            template.WhatsNext = item.content;
          }
          if (String(item.blockName).startsWith('HeaderTitle')) {
            template.HeaderTitle = item.content[0];
          } else {
            template[item.blockName] = item.content;
          }
        });
        setSponsoredTemplate(template);
      }
    };

    if (trial?.isSponsored) getSponsoredTemplate();
  }, [lang, trial, nctNumber]);

  const consultWithUs = ()=>{
    app.setPopup(POPUP_TYPES.CONSULT_WITH_US, { user: app.user });
  }

  const renderActionWidget = () => {
    if (app?.cache?.itemData?.action) return app.cache.itemData.action;
    return trial.isSponsored
      ? <Action action={()=>{
        if(app.user){
          consultWithUs();
        }else{
          app.setPopup(POPUP_TYPES.ADD_CAREGIVER, {
            theme: 'middle',
            onSignup: (user)=>{
              app.user = user;
              consultWithUs();
            },
          });
        }
      }} type={ACTION_TYPES.CONSULT_WITH_US} />
      : <Action type={ACTION_TYPES.CONNECT_WITH_ONCOLOGIST} data={{ trial }} />;
  };

  const renderActions = (type) => (
    <div>
      { type === 'mobile' ? (
        <div className="mobile-only">
          <div className="height-10" />
        </div>
      ) : null}
      <div className={`${type === 'mobile' ? 'mobile-only' : 'desktop-only'} `}>
        {/* { renderActionWidget(trial) } */}
        { sponsoredTemplate.WhatsNext ? <WhatsNext content={sponsoredTemplate.WhatsNext} /> : null}
        <div className="height-20" />
        {trial?.closest_facility?.facility_id ? <ClosestTrial trial={trial} /> : null}
      </div>
    </div>
  );

  return (
    trial ? (
      <div className="trial">
        {/* <div className="back-button-holder"><BackButton action={() => { window.location = ROUTES[PAGES.SEARCH]; }} /></div> */}
        <div className="back-button-holder"><BackButton /></div>
        {
          trialHeader || null
        }
        <div className="grid">
          <div className="widget">
            <TrialItem
              isFull
              item={trial}
              title={sponsoredTemplate?.HeaderTitle ?? null}
              logoSrc={sponsoredTemplate?.Logo}
            />
            {renderActions('mobile')}
            <div className="height-20" />
            <TrialDetails trial={trial} content={sponsoredTemplate.Description ? sponsoredTemplate.Description : null} />
          </div>
          {renderActions('desktop')}
        </div>
      </div>
    ) : null
  );
};

export default PublicTrial;
