import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as MessageIcon } from 'new-ui/assets/icons/message-text.svg';
import app from 'new-ui/app';
import { POPUP_TYPES } from 'new-ui/constants';
import './style.css';

const AssistanceButton = ({ title = 'Need Assistance?', onClick }) => {
  return (
    <div
      className="assistance-btn"
      onClick={() => {
        app.setPopup(POPUP_TYPES.CHAT);
        if (onClick) {
          onClick();
        }
      }}
    >
      <MessageIcon />
      <span className="label">
        {title}
      </span>
    </div>
  );
};

AssistanceButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default AssistanceButton;
