const HTTP_TOO_MANY_REQUESTS_ERROR_CODE = 429;

export function extractNetworkErrorTranslationKey(response) {
  if (!response || response.success) {
    return null;
  }
  if (response.errorCode === HTTP_TOO_MANY_REQUESTS_ERROR_CODE) {
    return 'error.too_many_requests';
  }
  return null;
}
