import React, { useEffect, useState } from 'react';
import './style.css';
import { useTranslation } from 'utils/modifiedTranslation';
import Preloader from 'new-ui/Components/Preloader';
import EventAddForm from './AddEventForm';
import EventActions from './EventActions';
import UpcomingEvent from './UpcomingEvent';
import ExpiredEvent from './ExpiredEvent';
import { addEvent, getEvents, deleteEvent } from '../api';
import { COMPONENT } from '..';
import { GA } from '..';
import app from 'new-ui/app';
import { getSurvey, setSurvey } from 'new-ui/Popups/TreatmentSurvey/api';
import { SURVEYS } from 'new-ui/constants';

const EventList = ({ onSelectType, selectedType }) => {
  const { t } = useTranslation();

  const SUB_COMPONENT = {
    FORM: 'form',
    ACTIONS: 'actions',
  };

  const [eventList, setEventList] = useState([]);
  const [selectedEventType, setSelectedEventType] = useState(selectedType);
  const [visibleComponent, setVisibleComponent] = useState(SUB_COMPONENT.FORM);
  const [showPreloader, setShowPreloader] = useState(false);

  const fetchEvents = async () => {
    setShowPreloader(true);
    const events = await getEvents();
    setEventList(events);
    setShowPreloader(false);
  };

  useEffect(()=> {
    fetchEvents();
  }, []);

  const handleEventDelete = async (id) => {
    setShowPreloader(true);
    try {
      await deleteEvent(id);
      // console.log(response.message); // Log success
      await fetchEvents(); // Refresh the event list
    } catch (error) {
      console.error('Error deleting event:', error); // Handle the error, e.g., display a message to the user
    } finally {
      setShowPreloader(false);
    }
  };

  const handleStatusBtnClick = () => {
    onSelectType(COMPONENT.STATUS_CARDS);
  };

  const handleMoodFeedback = async (id, mood) => {
    setShowPreloader(true);
    let lastTreatment = null;
    let surveyKey = null;
    if (app.user.condition_profile?.treatments?.length > 0) {
      lastTreatment = app.user.condition_profile.treatments[app.user.condition_profile.treatments.length - 1];
      surveyKey = lastTreatment.drugs_received.join('+');
    }else {
      setShowPreloader(false);
      return
    }

    try {
      const experiences = await getSurvey(SURVEYS.TREATMENT_EXPERIENCE, app.user.info.user_wix_id);
      const answers = (experiences.data.answers || []).filter(el => el.surveyKey !== surveyKey);
      answers.push({
        surveyKey: surveyKey,
        key: "aggression",
        value: mood
      });
      await setSurvey(SURVEYS.TREATMENT_EXPERIENCE, answers, app.user.info.user_wix_id);
    } catch (error) {
      console.error('Error adding mood', error);
    } finally {
      setShowPreloader(false);
    }
  };

  const handleTypeSelect = (type) => {
    app.sendGoogleAnalyticsEvent(GA.category, GA.events.scheduleAddClicked, {
      type: type,
    });
    setSelectedEventType(type);
    setVisibleComponent(SUB_COMPONENT.FORM);
  };

  const handleEventAdd = async (formData) => {
    setShowPreloader(true);
    await addEvent(formData);
    app.sendGoogleAnalyticsEvent(GA.category, GA.events.eventAddClicked, {
      type: formData.eventType,
    });
    await fetchEvents();
    setShowPreloader(false);
    setVisibleComponent(SUB_COMPONENT.ACTIONS);
  };

  const getUpcommingEvents = () => {
    return eventList.filter((event) => event.status === 'upcoming');
  };
  const getExpiredEvents = () => {
    return eventList.filter((event) => event.status === 'expired');
  };

  const upCommingEvents = getUpcommingEvents();
  const expiredEvents = getExpiredEvents();

  return (
    <div className="event-list">
      {(upCommingEvents.length > 0) && (
        <div className="next-appointments">
          <h3>{t('events_widget.list.upcoming.title')}</h3>
          <ul>
            {getUpcommingEvents().map((item, index) => (
              <li key={index}>
                <UpcomingEvent item={item} onDelete={handleEventDelete} />
              </li>
            ))}
          </ul>
        </div>
      )}

      {(expiredEvents.length > 0) && (
        <div className="last-visits">
          <h3>{t('events_widget.list.expired.title')}</h3>
          <ul>
            {getExpiredEvents().map((item, index) => (
              <li key={index}>
                <ExpiredEvent 
                  item={item} 
                  onDelete={()=>{}} onClickStatusBtn={handleStatusBtnClick} 
                  onClickMoodFeedback={handleMoodFeedback} 
                />
              </li>
            ))}
          </ul>
        </div>
      )}

      {visibleComponent === SUB_COMPONENT.ACTIONS && <EventActions onTypeSelect={handleTypeSelect} />}
      {visibleComponent === SUB_COMPONENT.FORM && <EventAddForm initialEventType={selectedEventType} onAddEvent={handleEventAdd} />}

      <Preloader isVisible={showPreloader} />
    </div>
  );
};
export default EventList;
